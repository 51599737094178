// @ts-nocheck
import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProfilePictureRounded from '../components/ProfilePictureRounded';
import OrganizationUpdateMemberShip from '../components/Organization/OrganizationUpdateMemberShip';

const OrganizationPage = () => {
    const { organizationId } = useParams();
    const user = useSelector((state) => state.user);
    
    const { loaded, data } = user.organizations

    const organizationData = data?.[organizationId]
    
    if(!loaded) {
        return <div>Loading</div>
    } else if (loaded && !organizationData) {
        return <div>could not load organization</div>
    }
const adminMember = organizationData.members.find((member) => organizationData.owner === member.id)
  return (
    <div className="flex flex-col grow items-center justify-center lg:flex-row">
      <div className="rounded-lg border border-gray-400 bg-white m-4 2xl:w-1/4 lg:w-4/12 md:w-3/5 sm:w-3/4 p-5">
        <div className='text-lg flex justify-center'>{organizationData.name} </div>
        <div className="flex justify-around m-2">
              <ProfilePictureRounded className={"border-black"} pictureURL={organizationData.profilePictureUrl} />
        </div>
        <div className="text-gray-500 text-sm font-normal font-Inter ml-1 flex justify-center">
            <div>{organizationData.description}</div>
        </div>
        {adminMember &&
        <div className='my-4'>
            <div>Administrator</div>
            <div className='flex my-2'>
                <div>
                    <div title={adminMember.email} className='flex justify-center'>
                            <ProfilePictureRounded diameter={64} className={"border-black"} pictureURL={adminMember.profilePictureUrl} />
                            </div>
                    <div className="text-gray-500 text-sm font-normal font-Inter ml-1 flex justify-center">{adminMember.name}</div>
                </div>
            </div>
        </div>
        }
        <div className='my-4'>
        <div>Members</div>
        <div className='flex'>
            {organizationData.members.map((member) => {
                return(
                    <div key={member.id} className='mr-2 my-2'>
                        <div title={member.email} className='flex justify-center'>
                        <ProfilePictureRounded diameter={64} className={"border-black"} pictureURL={member.profilePictureUrl} />
                        </div>
                        <div className="text-gray-500 text-sm font-normal font-Inter ml-1 flex justify-center">{member.name}</div>
                    </div>
                )
            })}
            </div>
        </div>
                <OrganizationUpdateMemberShip organizationId={organizationId} />
            
    </div>
    </div>
  )
}

export default OrganizationPage