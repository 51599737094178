// @ts-nocheck
import React, { useState } from "react";
import { ReactComponent as HomeLine } from "../assets/icons/home-line.svg";
import { ReactComponent as ChevronRight } from "../assets/icons/chevron-right.svg";
import { ReactComponent as Ellipsis } from "../assets/icons/ellipsis.svg";
import { useNavigate, useParams } from "react-router-dom";
import OrganizationPreview from "./Organization/OrganizationPreview";

export const AssetBreadCrumb = ({ assetGroupName, assetName, organization }) => {
  const navigate = useNavigate();
  const { groupid } = useParams();

  return (
    <>
      <div className="bg-white h-12 flex">
        <div className="flex grow items-center p-2 justify-between">
          <HomeLine
            onClick={() => navigate(`/home`)}
            className="w-5 h-5 ml-2 mr-1 cursor-pointer"
          />

          <div
            onClick={() => navigate(`/home`)}
            className="cursor-pointer text-gray-500 text-sm font-normal font-Inter mr-1 ml-3"
          >
            Asset Groups
          </div>
          {assetGroupName && (
            <>
              <ChevronRight className="mr-1" />
              <div
                onClick={() => navigate(`/home/group/${groupid}`)}
                className="cursor-pointer text-gray-500 text-sm font-normal font-Inter mr-1"
              >
                {assetGroupName}
              </div>
              {assetName && (
                <>
                  <ChevronRight className="mr-1" />
                  <div className="cursor-pointer text-gray-900 text-sm font-normal font-Inter mr-1">
                    {assetName}
                  </div>
                </>
              )}
            </>
          )}

          <div className="flex grow h-8 right">
            {/* {organization && <OrganizationPreview organization={organization} />} */}
          </div>
        </div>
        <div className="flex grow  right justify-end">
            {organization && <div className="p-2 flex">
              <OrganizationPreview organization={organization} />
              </div>}
          </div>
      </div>
    </>
  );
};

export const HeadBreadCrumb = ({ children, text }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white h-12">
      <div className=" flex grow items-center p-2">
        <div onClick={() => navigate(`/home`)} className="flex  cursor-pointer">
          {children}
          <div className="text-gray-900 text-sm font-normal ml-3 font-Inter mr-1">
            {text}
          </div>
        </div>

        <div className="flex grow h-8 ">
          {/* <div className="flex ml-auto">
            <img
              className="w-8 h-8 rounded-[100px] border-2 border-white"
              src="https://picsum.photos/32/32?random=1"
            />
          </div>
          <img
            className="w-8 h-8 ml-[-10px] rounded-[100px] border-2 border-white"
            src="https://picsum.photos/32/32?random=2"
          />
          <img
            className="w-8 h-8 ml-[-10px] rounded-[100px] border-2 border-white"
            src="https://picsum.photos/32/32?random=3"
          /> */}
        </div>
      </div>
    </div>
  );
};

export const NestedBreadCrumbs = ({ items, children }) => {
  const navigate = useNavigate();
  const { groupid } = useParams();

  // Craig edit this so last item does not cause navigation
  return (
    <>
      <div className="bg-white h-12">
        <div className="flex grow items-center p-2">
          {children}
          {items.map((item, i) => {
            return (
              <React.Fragment key={item.route}>
                <div
                  onClick={() => navigate(item.route)}
                  className="cursor-pointer text-gray-500 text-sm font-normal font-Inter mr-1 ml-3"
                >
                  {item.label}
                </div>
                {items.length !== i + 1 && <ChevronRight className="mr-1" />}
              </React.Fragment>
            );
          })}
          <div className=" h-8 "></div>
        </div>
      </div>
    </>
  );
};
