// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as CopyIcon } from "../assets/icons/copy-icon.svg";
import { ReactComponent as DotsVertical } from "../assets/icons/dots-vertical.svg";
import { ReactComponent as NoGroupsIcon } from "../assets/icons/no-groups-icon.svg";
import { ReactComponent as RightArrow } from "../assets/icons/right-arrow.svg";
import { ReactComponent as GroupIcon } from "../assets/icons/group-icon.svg";
import { AssetCount } from "./TagStyles";
import { setModalState, modalViewsConst } from "../store/modalSlice";
import { useSelector } from "react-redux";
import { fetchAssetsByGroupId } from "../store/assetsSlice";
import { getUrl } from '@aws-amplify/storage';
import bgpattern from "../assets/images/bg-pattern-01.svg"

export const AssetGroupFolder = ({ assetGroup }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groupSettingsRef = useRef();
  const [settingDialogueOpen, setSettingDialogueOpen] = useState(false);
  const [backgroundUrl, setBackgroundUrl] = useState(null);
  const { assets } = useSelector(({ assets }) => ({
    assets,
  }));

  useEffect(() => {
    const fetchBackgroundImage = async () => {
      if (assetGroup.profilePictureUrl) {
        try {
          const { url } = await getUrl({
            path: assetGroup.profilePictureUrl, // Path to the file in your S3 bucket
            options: {
              validateObjectExistence: true, // Optional: Validate the file exists
              expiresIn: 900, // Signed URL validity in seconds (default: 900, max: 3600)
              // useAccelerateEndpoint: true, // Optional: Use S3 Transfer Acceleration
            },
          });
          setBackgroundUrl(url);
        } catch (error) {
          console.error('Error fetching profile picture URL:', error);
        }
      }
    };

    fetchBackgroundImage();
  }, [assetGroup.profilePictureUrl]);

  useEffect(() => {
    // error handling here
    if (!assets.dataGroups[assetGroup.id]) {
      dispatch(fetchAssetsByGroupId(assetGroup.id));
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        groupSettingsRef.current &&
        !groupSettingsRef.current.contains(event.target)
      ) {
        setSettingDialogueOpen(false); // Clicked outside the div
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [groupSettingsRef]);

  const handleGroupSettings = (e) => {
    setSettingDialogueOpen(!settingDialogueOpen);
    e.stopPropagation();
  };

  const handleOuterDiv = (e) => {
    navigate(`/home/group/${assetGroup.id}`);
  };

  const handleEdit = (e) => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.editAssetGroup,
        properties: assetGroup,
      })
    );

    e.stopPropagation();
  };

  const handleDelete = (e) => {
    // CRAIG DO THIS LATER

    // setSettingDialogueOpen(false);
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.deleteAssetGroup,
        properties: assetGroup,
      })
    );

    e.stopPropagation();
  };

  return (
    <>
      <div
        onClick={handleOuterDiv}
        className={`cursor-pointer hover:border-gray-500 transition flex-row justify-center max-h-[250px] w-full sm:w-[300px] bg-eai-bg-lt2  bg-center rounded-md border border-gray-200 m-3 ${backgroundUrl ? "bg-cover" : "bg-center"}`}
        style={{
          backgroundImage: backgroundUrl
            ? `url(${backgroundUrl})`
            : `url(${bgpattern})`, // Default background image
          backgroundRepeat: "no-repeat",
          transition: "background-image 0.5s ease-in-out", // Smooth fade-in effect
        }}
      >
        <div className="flex w-10 h-10 bg-white rounded-md shadow border border-gray-200 m-3 mb-20 items-center justify-center">
          <GroupIcon className="h-6 w-6" />
        </div>
        <div className="bg-white bottom-div p-3">
          <div className="flex flex-row grow">
            <div className="flex flex-col grow">
              <div className="text-gray-900 text-lg font-semibold font-Inter">
                {assetGroup.name}
              </div>

              <AssetCount
                count={assets?.dataGroups?.[assetGroup.id]?.length ?? 0}
              />
            </div>
            <div className="relative" ref={groupSettingsRef}>
              {settingDialogueOpen && (
                <div
                  className="absolute top-full -mt-[134px] -ml-[66px] bg-white border shadow z-10"
                  onClick={(e) => e.stopPropagation()} // stop clicks from propagating
                >
                  <div
                    onClick={handleEdit}
                    className="p-2 hover:bg-gray-100"
                  >
                    Edit
                  </div>
                  <div
                    onClick={handleDelete}
                    className="p-2 hover:bg-gray-100"
                  >
                    Delete
                  </div>
                </div>
              )}
              <DotsVertical
                className="hover:border cursor-pointer"
                onClick={handleGroupSettings}
              />

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export const NoAssetGroup = ({ handleAddAssetGroup }) => {
  return (
    <>
      <div className="min-w-[400px] border rounded-md flex flex-col grow py-8 justify-center items-center">
        <NoGroupsIcon />
        <div className="text-gray-900 text-lg font-semibold font-Inter">
          No Asset Groups
        </div>
        <div className="w-[245px] text-center text-gray-500 text-sm mb-10 font-normal font-Inter">
          Create an asset group to begin creating asset profiles.
        </div>

        <div
          onClick={() => handleAddAssetGroup()}
          className="cursor-pointer flex flex-row justify-center items-center p-3 bg-white rounded-xl border border-gray-200"
        >
          <div className="w-12 h-12 shrink-0 flex items-center justify-center mr-3 bg-white rounded-[10px] border border-gray-200">
            <GroupIcon className="w-6 h-6" />
          </div>
          <div className="flex flex-col grow justify-center">
            <div className="text-gray-900 mb-1 text-base font-semibold font-Inter">
              Create an Asset Group
            </div>
            <div className="text-gray-400 text-sm font-normal font-Inter">
              Before you add an asset, create your first group.{" "}
            </div>
          </div>
          <div className="w-12 h-12 shrink-0 flex items-center justify-center ml-3">
            <RightArrow className="w-6 h-6" />
          </div>
        </div>
      </div>
    </>
  );
};
