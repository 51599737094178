// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AssetListNEW from "../components/AssetListNEW";
import { AssetBreadCrumb } from "../components/AssetBreadCrumb";
import AssetDescription from "../components/AssetDescription";
import AssetGraph from "../components/AssetGraph";
import AssetAlerts from "../components/AssetAlerts";
import { useParams } from "react-router-dom";
import LoadingIcon from "../components/LoadingIcon";
import { fetchAssetGroupById } from "../store/assetGroupSlice";
import { fetchAssetsByGroupId, fetchGroupsAndNestedAssets } from "../store/assetsSlice";
import { generateClient } from "aws-amplify/api";
import { getUser } from "../graphql/queries";
import ExploreDashboard from "../components/ExplorePage/ExploreDashboard";

const ExplorePage = () => {
  const dispatch = useDispatch();
  const [assetOwner, setAssetOwner] = useState(null);
  const { assets, assetGroups, user } = useSelector(
    ({ assets, assetGroups, user }) => ({
      assets,
      assetGroups,
      user,
    })
  );

  const fetchGroupsAndAssets = async () => {
    await dispatch(fetchGroupsAndNestedAssets())
  }

  useEffect(() => {
    fetchGroupsAndAssets()
  },[])
  

  return (
    <ExploreDashboard />
  );
};

export default ExplorePage;
