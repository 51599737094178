// @ts-nocheck
import React, { useEffect, useState } from "react";
import { list } from "aws-amplify/storage";
import { uploadData, downloadData } from "@aws-amplify/storage";
import { setSensitivityModelRenderProperties, extractDateFromFilename, resourceTypeEnum } from "./utils";

// Placeholder for now
const colors = [
  "#FF5733", // Vibrant Red-Orange
  "#33FF57", // Lime Green
  "#3357FF", // Bright Blue
  "#FF33A8", // Hot Pink
  "#FFFF33", // Bright Yellow
  "#33FFF6", // Aqua
];

const SensitivityModelDataSourceForm = ({
  selectedAssetId,
  dataSourceRecord,
  sensitivityModels,
  onSubmit,
  onSourceChange,

}) => {

  const [sensitivityModel, setSensitivityModel] = useState("");
  const [recordList, setRecordList] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const [fileName, setfileName] = useState(null);

  const [dataSourceRecordId] = Object.keys(dataSourceRecord)

  useEffect(() => {
    if (fileName) {
      const submitData = {
        //anything in here triggers re-render from file
        resource: {
          resourceType: resourceTypeEnum.sensitivityModel,
          fileSelected: fileName,
          latest: Boolean(dateSelected === "latest"),
          recordId: sensitivityModel.id,
          issued: "no known",
        },
        // anything in here doesn't trigger re-render
        attributes: {
          submited: false,
          name: sensitivityModel.label,
          description: sensitivityModel.description,
          dataSets: {
            id: 1,
          },
        },
        renderProps: setSensitivityModelRenderProperties(
          JSON.parse(sensitivityModel.schema)
        ),
        data: {
          status: "unfetched",
          schema: JSON.parse(sensitivityModel.schema), //use setSensitivityModelRenderProperties BUT we need initial
          values: "",
        },
      };
      onSourceChange({[dataSourceRecordId]:submitData});
    }
  }, [fileName]);

  // Extract to util
  const fetchModelList = async (model) => {
    const result = await list({
      path: model.routeLong,
    });
    const items = result.items.map((item) => {
      return { ...item, file: item.path.replace(model.routeLong, "") };
    });
    setRecordList(items)
  }

  useEffect(() => {
    setSensitivityModel(null)
    setRecordList(null)
  },[selectedAssetId])

  const handleSensitivityModelChange = async (e) => {
    const model = sensitivityModels.find(
      (element) => element.routeShort === e.target.value
    );
    setSensitivityModel(model);
    fetchModelList(model);
  };

  const handleDateChange = (e) => {
    const newValue = e.target.value
    setDateSelected(newValue);
    let fileNameNew = newValue;
    let latest = false;
    if (newValue === "latest") {
      fileNameNew = sensitivityModel.routeLong;
      latest = true;
    }
    setfileName(fileNameNew);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <div className="flex">
      <div className="flex-1">
      <div className="flex-1">
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Sensitivity Models
        </div>
        <div className="flex grow"></div>
        <select
          // value={selectedOption}  Craig update this
          className="flex grow border p-3 mb-5 text-md rounded-md"
          name="sensitivityModel"
          onChange={handleSensitivityModelChange}
        >
          <option value="" disabled selected>
            Select one
          </option>
          {sensitivityModels.map((sensitivityModel) => (
            <option
              key={sensitivityModel.routeShort}
              value={sensitivityModel.routeShort}
            >
              {sensitivityModel.label}
            </option>
          ))}
        </select>
      </div>

      {recordList !== null ? (
        <div className="flex-1">
          <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
            Date Issued
          </div>
          <div className="flex grow"></div>
          <select
            // value={selectedOption} Craig update this
            className="flex grow border p-3 mb-5 text-md rounded-md"
            name="dateIssued"
            onChange={handleDateChange}
          >
            <option value="" disabled>
              Select one
            </option>
            <option value="latest">Most Recent</option>
            {recordList.map((item) => (
              <option key={item.eTag} value={item.path}>
                {extractDateFromFilename(item.file)}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      {dateSelected && (
        <button className="eai-btn" onClick={handleSubmit}>
          Submit
        </button>
      )}
      </div>

    </div>
  );
};

export default SensitivityModelDataSourceForm;
