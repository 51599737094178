// @ts-nocheck
import React from 'react'
import { useSelector } from 'react-redux';
import ProfilePictureRounded from '../ProfilePictureRounded';
import { useNavigate } from 'react-router-dom';

const MyOrganizationList = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate()
  
  const { loaded, data } = user.organizations
  const organizationKeys = Object.keys(data)

  if(!loaded) {
    return false
  } else if (organizationKeys.length === 0) {
    return false
  }

  return (
    <div>
        <div className='text-lg mt-4 mb-2'>My Organizations</div>
        <div className='flex justify-around'>
            {organizationKeys.map((orgKey) => {
                const organizationData = data[orgKey]
                 return (               
                <div key={orgKey} className='hover:cursor-pointer' onClick={()=>navigate(`/home/organization/${organizationData.id}`)}>
                    <div className='flex justify-center my-1'>
                        <ProfilePictureRounded className={"border-black"} pictureURL={organizationData?.profilePictureUrl} diameter={64} />
                    </div>
                    <div className="text-gray-500 text-sm font-normal font-Inter ml-1">{organizationData.name}</div>
                </div>

                )
            })
            }
        </div>
    </div>
  )
}

export default MyOrganizationList