// @ts-nocheck
import React, { useState } from "react";
import ProcessedModelDataSourceForm from "./ProcessedModelDataSourceForm";
import AdditionalDataSourceForm from "./AdditionalDataSourceForm";
import { fetchDataSources } from "../../api/awsLamba";
import SensitivityModelDataSourceForm from "./SensitivityModelDataSourceForm";
import Select from "../ReusableComponents/SelectInput";
import { useSelector } from "react-redux";
import AssetSelectAnalysis from "./AssetSelectInput"
import DataTypeFormAssetForecast from "./DataTypeFormAssetForecast";
import DataSourceStyleUpdate from "./DataSourceStyleUpdate/DataSourceStyleUpdate";

const dataSourceTypesEnum = {
  additionalSources: "additionalSources",
  assetForecasts: "assetForecasts",
  uploadData: "uploadData"
};

const ExploreDataSourceForm = ({
  selectedAsset,
  onSubmitForm,
  onChange,
  dataSourceRecord,
  dataSourceRecordId,
  onDataSourceChange,
}) => {

  const [selectedOption, setSelectedOption] = useState(""); // State to manage the selected option
  const [selectedDataSourceType, setSelectedDataSourceType] = useState("")
  const [additionalDataSources, setAdditionalDataSources] = useState([]);

  const handleSelectChange = async (event) => {
    const selection = event.target.value;
    setSelectedDataSourceType(selection)

    // Do we need this piece >>>
    switch (selection) {
      case dataSourceTypesEnum.additionalSources:
        if (additionalDataSources.length === 0) {
          const additionalDataSourcesFetch = await fetchDataSources();
          setAdditionalDataSources(additionalDataSourcesFetch);
        }
        return;
      case dataSourceTypesEnum.sensitivityModels:
        console.log(
          "dataSourceTypes.sensitivityModels",
          dataSourceTypesEnum.sensitivityModels
        );
        return;
      default:
        break;
    }
    // Do we need this piece <<<
  };


  const renderDataSourceType = () => {
      switch (selectedDataSourceType) {
      case dataSourceTypesEnum.assetForecasts:
        return (
          <DataTypeFormAssetForecast dataSourceRecord={dataSourceRecord} onDataSourceChange={onDataSourceChange} onSubmitForm={onSubmitForm} />
        );
      case dataSourceTypesEnum.additionalSources:
        return (
          <div>Additional Sources Under Construction</div>
        );
      case dataSourceTypesEnum.uploadData:
        return (
          <div>Upload Data Under Construction</div>
        );
      default:
        return <div>Not available</div>;
    }

  }
 
  if (!dataSourceRecord) {
    console.log("Doesn't exits");
    return false;
  }

  return (
    <div className="m-2 flex">
      <div className="flex-1 bg-red-200 ">
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Data Source
        </div>
        <div className="flex grow"></div>
        <select
          value={selectedDataSourceType}
          onChange={handleSelectChange}
          className="flex grow border p-3 mb-5 text-md rounded-md"
        >
          <option value="" disabled hidden>
            Select one
          </option>
          <option value={dataSourceTypesEnum.assetForecasts}>Asset Forecast</option> 
          <option value={dataSourceTypesEnum.additionalSources}>Additional Data Sources - under construction</option>
          <option value={dataSourceTypesEnum.uploadData} disabled>uploadData - under Construction</option>
        </select>
      {renderDataSourceType()}
      </div>
      <div className="bg-blue-200 flex-1">
            <DataSourceStyleUpdate dataSourceRecord={dataSourceRecord} onChange={onDataSourceChange} />
      </div>
    </div>
  );
};

export default ExploreDataSourceForm;
