// @ts-nocheck
import React from "react";
import withAdminAuth from "../components/HOC/withAdminAuthPage";
import LoginAccessCodesCreateForm from "../ui-components/LoginAccessCodesCreateForm";
import LoginAccessCodes from "../components/Admin/LoginAccessCodes";
import AdminAddOrganization from "../components/AdminAddOrganization";
import OrganizationPreviewPage from "./OrganizationPreviewPage";
import TestEndpoints from "./TestEndpoints";

const Admin = () => {
  return (
    <>
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow p-5">
          <div className="text-xl">Admin Page</div>
          <div>
            <OrganizationPreviewPage />
            <LoginAccessCodes />
            {/* <TestEndpoints /> */}
            <AdminAddOrganization />
          </div>
        </div>
      </div>
    </>
  );
};

export default withAdminAuth(Admin);
