// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { listUsers } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { createOrganization, createUserOrganizations } from '../graphql/mutations';
import { createUserOrganizationsCustom } from '../graphql/customMutations'
import ProfilePictureRounded from './ProfilePictureRounded';
import { uploadData } from '@aws-amplify/storage';

const defaultFormState = {
  name:"",
  description:"",
  owner:""
}

const client = generateClient();
const AdminAddOrganization = () => {

    const [file, setFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [userList, setUserList] = useState([])
    const [formState, setFormState] = useState(defaultFormState)
    const [selectedItems, setSelectedItems] = useState([]);
    const [formComplete, setFormComplete] = useState(false)
    const [organizationOwner, setOrganizationOwner] = useState(null)

    // set user state
    const getAllUsers = async () => {
        const client = generateClient();
        const { data } = await client.graphql({
            query: listUsers,
          });
          setUserList(data.listUsers.items)
    }
    // submit if it works, green light!

    useEffect(()=>{
        getAllUsers()
    },[])

    const handleChange = (e) => {
      if(e.target.name === "owner") {
        const selectedOwner = userList.find((userProfiles) => userProfiles.id === e.target.value)
        console.log("selectedOwner", selectedOwner)
        setOrganizationOwner(selectedOwner)
      }
        setFormState((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value, // Update the specific field
          }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try{
            const organizationId = formState.name.toLowerCase().replace(/\s/g,'')
            let fileKey = await handleUploadFile(file, organizationId)
            let submitForm = {...formState}
            submitForm["id"] = organizationId
            submitForm["profilePictureUrl"] = fileKey
            
            console.log("SUBMITFORM", submitForm)
            const { data } = await client.graphql({
                query: createOrganization,
                variables: { input:submitForm },
            });

            const organizationSubmitted = data.createOrganization
            console.log("organizationSubmitted", organizationSubmitted)
            for (const userId of selectedItems) {
                
                const { data } = await client.graphql({
                    query: createUserOrganizationsCustom,
                    variables: { input:{
                      organizationId: organizationSubmitted.id,
                        userID: userId
                                } 
                            },
                    });
                console.log("Create user Organization", data)
            }
            setFormState({})
            setSelectedItems([])
            setFormComplete(true)

        } catch (e) {
            console.log("errror", e)
        }
    }

    const isSelected = (id) => selectedItems.includes(id);

    const handleSelect = (id) => {
        setSelectedItems((prevSelected) => {
          if (prevSelected.includes(id)) {
            // If item is already selected, remove it
            return prevSelected.filter((item) => item !== id);
          } else {
            // If item is not selected, add it
            return [...prevSelected, id];
          }
        });
      };

    const handleFileChange = (event) => {
      const fileUploaded = event.target.files[0];
          if (fileUploaded) {
          // Use FileReader to generate a preview URL
          const reader = new FileReader();
          reader.onloadend = () => {
              setSelectedImage(reader.result); // Set the preview URL
          };
          reader.readAsDataURL(fileUploaded); // Read the file as a Data URL
          setFile(fileUploaded)
          } else {
          setSelectedImage(null); // Clear preview if no file is selected
          }
        };

        const handleUploadFile = async (uploadFile, organizationId) => {
          console.log("organizationOwner.cognitoIdentityId", organizationOwner.cognitoIdentityId)
          if (!uploadFile || !organizationId || !organizationOwner?.cognitoIdentityId) return null;
          try {
              const result = await uploadData({
                path: ({identityId}) => {
                  // let ownerCogId = organizationOwner.cognitoIdentityId
                  const path = `organizationprotected/${organizationId}/profilePic/${uploadFile.name}`  
                  console.log("Path", path)
                  return path
                },
                data: uploadFile,
                options: {
                  // onProgress // Optional progress callback.
                }
              }).result;
            return result.path; // Return the file's S3 location key
          } catch (error) {
            console.error("Error uploading file:", error);
            return null;
          }
      }


  return (
    <div>

    <div className='text-lg'>AdminAddOrganization</div>
        <form onSubmit={handleSubmit}>
            <div
                name="name"
                className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
            >
                    Organization Name
            </div>
            <input className='border p-2' value={formState.name} onChange={handleChange} name="name"></input>
            <div
                name="name"
                className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
            >
                    Description
            </div>
            <textarea className='border p-2' value={formState.description} name="description" onChange={handleChange}></textarea>
            <div
                name="name"
                className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
            >
                    Owner
            </div>
            <select className='bg-green-100 p-2 m-2 rounded' value={formState.owner} onChange={handleChange} name="owner">
                {userList.map((user)=> <option value={user.id} key={user.id}>{user.name} - {user.email}</option>)}
            </select>

            <div className='w-96 bg-slate-200'>
                <div>Choose Company Profile Picture</div>
              <div className="mt-4   flex justify-around">
                <div className="text-center">
              {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Preview"
                    className="w-32 h-32 rounded-full border-2 border-black object-cover"
                  />)
                  :
                  <ProfilePictureRounded  />
              }
                </div>
              </div>
          
            <div className="flex items-center justify-center  pt-5">
              
              
                <div>

                <input
                  id="profile-photo"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 hover:cursor file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary-50 file:text-primary-700 hover:file:bg-primary-100"
                  />
                </div>  
            </div>
          </div>
            <div
                name="name"
                className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
            >
                    Members
            </div>
            <ul>
            {userList.map((option)=> (
              <li key={option.id} style={{ marginBottom: "8px" }}>
                <label>
                  <input
                    type="checkbox"
                    checked={isSelected(option.id)}
                    onChange={() => handleSelect(option.id)}
                  />
                  {option.name} - {option.email}
                </label>
              </li>
            ))}
          </ul>
          <button type='submit' className='bg-blue-300 p-2 rounded m-2'>Create Organization</button>
          {/* 
          - check if you can add user and view asset group
          */}
          {formComplete && <div className='bg-green-300'> COMPLETE!</div>}
        </form>
    </div>
  )
}

export default AdminAddOrganization