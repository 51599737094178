// @ts-nocheck
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { authenticationState, logOutUser, updateUserRequest } from "../store/userSlice";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import { getLoginAccessCodes, getUser } from "../graphql/queries";
import { modalViewsConst, setModalState } from "../store/modalSlice";
import { getUrl, list, uploadData } from "aws-amplify/storage";
import { record, flushEvents } from "aws-amplify/analytics";
import { fetchDataSources, fetchDataSourcesRecord } from "../api/awsLamba";
import ProfilePictureRounded from "./ProfilePictureRounded";
import TestEndpoints from "../pages/TestEndpoints";
import MyOrganizationList from "./Organization/MyOrganizationList";

const defaultSignupForm = {
  email: "",
  password: "",
  confirmPassword: "",
  username: "",
  accessCode: "",
};

//needed for public access
const client = generateClient({ authMode: "identityPool" });

export const CreateAccount = ({ onFormSubmit, serverSideErrors }) => {
  const [formState, setFormState] = useState(defaultSignupForm);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfVisible, setPasswordConfVisible] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const fetchAccessCode = async (code) => {
    try {
      const { data } = await client.graphql({
        query: getLoginAccessCodes,
        variables: {
          code,
        },
      });

      return data.getLoginAccessCodes;
    } catch (error) {
      console.log("access Code Load Error", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = [];
    if (formState.password.length < 6) {
      errors.push("password must have at least 7 characters");
    }
    if (formState.password !== formState.confirmPassword) {
      errors.push("password and confirm password do not match");
    }
    if (formState.email.trim().length === 0) {
      errors.push("you must include an email address");
    }
    if (formState.username.trim().length < 3) {
      errors.push("your username must include more than 3 characters");
    }

    const accessCode = await fetchAccessCode(formState.accessCode);
    if (!accessCode) {
      errors.push("your access code is invalid");
    }

    if (accessCode && !accessCode.available) {
      errors.push("The access code you provided has already been used.");
    }

    setErrorMessages([...errors]);

    if (errors.length === 0) {
      onFormSubmit(formState);
    }
  };

  return (
    <form className="p-4" onSubmit={handleSubmit}>
      <div className="mb-2 text-lg">
        <div className="font-Inter font-semibold">Email</div>
        <div className="flex">
          <div className="flex-1 flex rounded border border-black">
            <input
              className="p-1 flex-1 rounded "
              type="email"
              value={formState["email"]}
              name="email"
              onChange={(e) =>
                setFormState({ ...formState, [e.target.name]: e.target.value })
              }
            ></input>
          </div>
        </div>
      </div>
      <div className="mb-2 text-lg">
        <div className="font-Inter font-semibold">Password</div>
        <div className="flex">
          <div className="flex-1 flex rounded border border-black">
            <input
              className="p-1 align-middle flex-1 rounded-l-lg"
              type={passwordVisible ? "string" : "password"}
              value={formState["password"]}
              name="password"
              onChange={(e) =>
                setFormState({ ...formState, [e.target.name]: e.target.value })
              }
            ></input>
            <button
              className="p-1 align-middle bg-white rounded-r-lg border-l-2 border-black"
              type="button"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? (
                <AiOutlineEyeInvisible fontSize="1.4em" />
              ) : (
                <AiOutlineEye fontSize="1.4em" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="mb-2 text-lg">
        <div className="font-Inter font-semibold">Confirm Password</div>
        <div className="flex">
          <div className="flex-1 flex rounded border border-black">
            <input
              className="p-1 align-middle flex-1 rounded-l-lg"
              type={passwordConfVisible ? "string" : "password"}
              value={formState["confirmPassword"]}
              name="confirmPassword"
              onChange={(e) =>
                setFormState({ ...formState, [e.target.name]: e.target.value })
              }
            ></input>
            <button
              className="p-1 align-middle bg-white rounded-r-lg border-l-2 border-black"
              type="button"
              onClick={() => setPasswordConfVisible(!passwordConfVisible)}
            >
              {passwordConfVisible ? (
                <AiOutlineEyeInvisible fontSize="1.4em" />
              ) : (
                <AiOutlineEye fontSize="1.4em" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="mb-4 text-lg">
        <div className="font-Inter font-semibold">Username</div>
        <div className="flex">
          <div className="flex-1 flex rounded border border-black">
            <input
              className="p-1 align-middle flex-1 rounded"
              type="string"
              value={formState["username"]}
              name="username"
              onChange={(e) =>
                setFormState({ ...formState, [e.target.name]: e.target.value })
              }
            ></input>
          </div>
        </div>
      </div>
      <div className="mb-4 text-lg">
        <div className="font-Inter font-semibold">Access Code</div>
        <div className="flex">
          <div className="flex-1 flex rounded border border-black">
            <input
              className="p-1 align-middle flex-1 rounded"
              type="string"
              value={formState["accessCode"]}
              name="accessCode"
              onChange={(e) =>
                setFormState({ ...formState, [e.target.name]: e.target.value })
              }
            ></input>
          </div>
        </div>
      </div>
      {(errorMessages.length > 0 || serverSideErrors.length > 0) && (
        <div className="flex mb-4 mt-4 text-lg border-red-500 border p-2 bg-white rounded">
          <div className="flex-1">
            {errorMessages.length > 0 &&
              errorMessages.map((error) => {
                return (
                  <div className="text-red-500" key={error}>
                    * {error}
                  </div>
                );
              })}
            {serverSideErrors.length > 0 && (
              <div className="text-red-500">* {serverSideErrors}</div>
            )}
          </div>
        </div>
      )}
      <div className="flex mb-2">
        <button className="flex-1 eai-btn-grn" type="submit">
          Create Account
        </button>
      </div>
    </form>
  );
};

export const ConfirmSignUp = ({
  onSubmit,
  onResendCode,
  userEmail,
  serverSideErrors,
}) => {
  const [confirmationInput, setConfirmationInput] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(confirmationInput);
  };

  return (
    <form className="p-4" onSubmit={handleSubmit}>
      <div className="text-xl mb-2">We Emailed You</div>
      <div className="text-md mb-2">
        To log in, enter the code we went to {userEmail}. It may take a minute
        or two to arrive.
      </div>
      <div className="mb-2 text-lg">
        <div className="mb-2">Confirmation Code</div>
        <div className="flex">
          <div className="flex-1 flex rounded border border-black">
            <input
              className="p-1 flex-1 rounded "
              type="number"
              value={confirmationInput}
              name="email"
              onChange={(e) => setConfirmationInput(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      {serverSideErrors.length > 0 && (
        <div className="flex mb-4 mt-4 text-lg border-red-500 border p-2 bg-white rounded">
          <div className="flex-1">
            <div className="text-red-500">* {serverSideErrors}</div>
          </div>
        </div>
      )}

      <div className="flex mb-2">
        <button
          className="transition border flex-1 hover:bg-blue-200 bg-sky-700 hover:text-black text-white rounded-lg p-2"
          type="submit"
        >
          Confirm
        </button>
      </div>
      <div className="flex">
        <button
          onClick={onResendCode}
          className="transition border flex-1 hover:bg-blue-200 bg-white text-black rounded-lg p-2"
          type="button"
        >
          Resend Code
        </button>
      </div>
    </form>
  );
};

export const SignIn = ({ onSubmit, serverSideErrors, onForgotPassword }) => {
  const [email, setEmail] = useState("");
  const [password, setPassWord] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    if (setErrorMessages.length > 0) {
      setErrorMessages(serverSideErrors);
    }
  }, [serverSideErrors]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    onSubmit({ email, password });
  };

  return (
    <form className="p-4" onSubmit={handleSubmitForm}>
      <div className="mb-2 text-lg">
        <div className="font-Inter font-semibold">Email</div>
        <div className="flex-1 flex rounded border border-black">
          <input
            className="p-1 flex-1 rounded"
            type="email"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
      </div>
      <div className="mb-4 text-lg">
        <div className="flex justify-between">
          <div className="font-Inter font-semibold">Password</div>
          <div
            onClick={() => onForgotPassword()}
            className="cursor-pointer font-Inter  text-eai-secondary-blue hover:underline"
          >
            Forgot your password?
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 flex rounded border border-black">
            <input
              className="p-1 align-middle flex-1 rounded-l-lg"
              type={passwordVisible ? "string" : "password"}
              value={password}
              name="password"
              onChange={(e) => setPassWord(e.target.value)}
            ></input>
            <button
              className="p-1 align-middle bg-white rounded-r-lg border-l-2 border-black"
              type="button"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? (
                <AiOutlineEyeInvisible fontSize="1.4em" />
              ) : (
                <AiOutlineEye fontSize="1.4em" />
              )}
            </button>
          </div>
        </div>
      </div>
      {errorMessages.length > 0 && (
        <div className="flex mb-4 text-lg border-red-500 border p-2 bg-white rounded">
          <div className="flex-1">
            <div className="text-red-500">
              {errorMessages.length > 0 && `*${errorMessages}`}
            </div>
          </div>
        </div>
      )}
      <div className="flex mb-2">
        <button className="flex-1 eai-btn-grn" type="submit">
          Sign In
        </button>
      </div>
    </form>
  );
};

export const LoggedInDisplay = () => {
  // const { user, signOutMutation } = useGetMe();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  //Craig Move this to Redux newhire
  const handleSignOut = async () => {
    // CRAIG RESET ALL REDUX STATE
    const res = await signOut();
    dispatch(logOutUser());
    navigate("/");
    window.location.reload();

    //Craig newHire, pending state after moving logout
  };

  const handleChangePassword = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.passwordChange,
        properties: null,
      })
    );
  };

  const fetchIdentity = async () => {
    // const res = await fetchAuthSession()
    // console.log("identity", res)
  };

  const handlePermission = async () => {
    console.log("test something");
    try {
      const fileList = await list({
        path: ({ identityId }) =>
          // us-east-1:63445abc-adce-cfa6-1f1a-3492e231a01d
          "private/us-east-1:63445abc-adce-cfa6-1f1a-3492e231a01d/processedModels/c6ae10e5-5d3e-4b31-87eb-103b178da5f9/turbineflows/",
        // `private/${identityId}/processedModels/${asset.id}/${modelType}/`,
      });
      console.log("fileList", fileList);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const handleTestAnalytics = async () => {
    // const recordSent = await record({
    //   name: 'UserAccountVisit',
    //   attributes: { genre: 'test', artist: 'toast' },
    // });
    // console.log("recordSent", recordSent)
    await flushEvents();
  };

  const handletestPostGres = async () => {
    const res = await fetchDataSources();
    console.log("res", res);
  };
  const handletestPostGresRecord = async () => {
    const res = await fetchDataSourcesRecord();

    console.log("res", res);
  };

  const handleChangeProfilePicture = () => {
    dispatch(setModalState({
      modalOpen: true,
      modalView: modalViewsConst.changeProfilePicture,
      properties: null,
    }))
  }

  //Craig For now
  if (!user) {
    return false;
  }

  return (
    <div className="flex flex-col grow items-center justify-center lg:flex-row">
      <div className="rounded-lg border border-gray-400 bg-white m-4 2xl:w-1/4 lg:w-4/12 md:w-3/5 sm:w-3/4 p-5">
        <div className="mb-4 text-center">
          {user.status === authenticationState.authenticated ? (
            <>
          <div className="text-xl font-medium" onClick={fetchIdentity}>{user.details.username}</div>
          <div className="text-gray-500 text-sm font-normal font-Inter ml-1">{user.details.email}</div>
            <div className="flex justify-around  my-4">
              <ProfilePictureRounded onClick={handleChangeProfilePicture} className={"border-black cursor-pointer"} pictureURL={user?.permissionDetails?.profilePictureUrl} />
            </div>
              {/* <div className="m-2">
                <button onClick={handleChangeProfilePicture} className="eai-btn">Change Profile Picture</button>
              </div> */}
              
              {/* <div className="mb-2">
                <b>Email: </b>
                {user.details.email}
              </div> */}
              <div className="mb-2">
                <button className="eai-btn-grn" onClick={handleChangePassword}>
                  Change Password
                </button>
              </div>
              <div>
                <MyOrganizationList />
              </div>
            </>
          ) : (
            "Logging out"
          )}
        </div>
        <div className="flex justify-around">
          <button className="eai-btn-red" onClick={handleSignOut}>
            Sign Out
          </button>
          {/* <TestEndpoints /> */}
          {/* <button onClick={handleTestAnalytics} className="border bg-slate-400">
          Test Something
          </button> */}
          {/* <button onClick={handletestPostGres} className="border bg-slate-400">
            Test Postgres
          </button> */}
          {/* <button
            onClick={handletestPostGresRecord}
            className="border bg-slate-400"
          >
            Test Postgres record
          </button> */}
        </div>
      </div>
    </div>
  );
};

export const ResetPasswordEmailView = ({
  onCancel,
  errorMessages = [],
  onSubmit,
}) => {
  const [email, setEmail] = useState("");

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  return (
    <form className="p-4" onSubmit={handleSubmitForm}>
      <div className="mb-2 text-lg">
        <div className="font-Inter font-semibold">Email</div>
        <div className="flex-1 flex rounded border border-black">
          <input
            className="p-1 flex-1 rounded"
            type="email"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
      </div>

      {errorMessages.length > 0 && (
        <div className="flex mb-4 text-lg border-red-500 border p-2 bg-white rounded">
          <div className="flex-1">
            {errorMessages.map((errorMessage) => {
              return (
                <div key={errorMessage} className="text-red-500">
                  {`*${errorMessage}`}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex mb-2">
        <button className="flex-1 eai-btn-grn" type="submit">
          Send Reset Code
        </button>
      </div>
      <div className="flex mb-2">
        <button onClick={handleCancel} className="flex-1 eai-btn" type="cancel">
          Cancel
        </button>
      </div>
    </form>
  );
};

const defaultResetPasswordState = {
  resetCode: "",
  newPassword: "",
  confirmNewPassword: "",
};

export const ResetPassword = ({
  onCancel,
  errorMessages = [],
  resetCodeEmail,
  onSubmit,
}) => {
  const [resetPasswordState, setResetPasswordState] = useState(
    defaultResetPasswordState
  );
  const handleSubmitForm = (e) => {
    e.preventDefault();
    onSubmit(resetPasswordState);
  };

  return (
    <form className="p-4" onSubmit={handleSubmitForm}>
      <div className="mb-2 text-lg">
        <div className="text-sm mb-2">
          A reset code was sent to {resetCodeEmail} . Enter the reset code and
          your updated password.
        </div>
        <div className="font-Inter font-semibold">Reset Code</div>
        <div className="flex-1 flex rounded border border-black">
          <input
            className="p-1 flex-1 rounded"
            value={resetPasswordState.resetCode}
            name="resetCode"
            onChange={(e) =>
              setResetPasswordState({
                ...resetPasswordState,
                resetCode: e.target.value,
              })
            }
          ></input>
        </div>
      </div>
      <div className="mb-2 text-lg">
        <div className="font-Inter font-semibold">New Password</div>
        <div className="flex-1 flex rounded border border-black">
          <input
            className="p-1 flex-1 rounded"
            value={resetPasswordState.newPassword}
            name="newPassword"
            onChange={(e) =>
              setResetPasswordState({
                ...resetPasswordState,
                newPassword: e.target.value,
              })
            }
          ></input>
        </div>
      </div>
      <div className="mb-2 text-lg">
        <div className="font-Inter font-semibold">Confirm Password</div>
        <div className="flex-1 flex rounded border border-black">
          <input
            className="p-1 flex-1 rounded"
            value={resetPasswordState.confirmNewPassword}
            name="confirmNewPassword"
            onChange={(e) =>
              setResetPasswordState({
                ...resetPasswordState,
                confirmNewPassword: e.target.value,
              })
            }
          ></input>
        </div>
      </div>

      {errorMessages.length > 0 && (
        <div className="flex mb-4 text-lg border-red-500 border p-2 bg-white rounded">
          <div className="flex-1">
            {errorMessages.map((errorMessage) => {
              return (
                <div key={errorMessage} className="text-red-500">
                  {`*${errorMessage}`}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex mb-2">
        <button className="flex-1 eai-btn-grn" type="submit">
          Reset Password
        </button>
      </div>
      <div className="flex mb-2">
        <button
          onClick={() => onCancel()}
          className="flex-1 eai-btn"
          type="cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
