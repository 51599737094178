// @ts-nocheck
import React from 'react'
import SensitivityModelStyleUpdate from './SensitivityModelStyleUpdate'
import ProcessedModelStyleUpdate from './ProcessedModelStyleUpdate'
import { resourceTypeEnum } from '../utils'

const DataSourceStyleUpdate = ({ dataSourceRecord, onChange}) => {
    const [dataSourceRecordId] = Object.keys(dataSourceRecord)
    const record = dataSourceRecord[dataSourceRecordId]
    const {resource, renderProps} = record
  
    if(!renderProps) {
        return <div></div>
    }
        
     
 switch(resource.resourceType) {
    case resourceTypeEnum.sensitivityModel:
        return <SensitivityModelStyleUpdate dataSourceRecord={dataSourceRecord}  onChange={onChange}  />
    case resourceTypeEnum.processedModel:
      return <ProcessedModelStyleUpdate dataSourceRecord={dataSourceRecord}  onChange={onChange}  />
    default:
      return (
        <div>DataSourceStyleUpdate</div>
      )

 }

}

export default DataSourceStyleUpdate