// @ts-nocheck
import React from 'react'

const AssetSelectInput = ({ selectedAsset, onChange, assetList, assetGroups }) => {
  
    const handleSelectChange = (e) => {
      const selectedAsset = assetList.find(asset => asset.id === e.target.value)
      onChange(selectedAsset)
    }

  return (
    <select
          value={selectedAsset.id}
          onChange={handleSelectChange}
          className="flex grow border p-3 mb-5 text-md rounded-md"
        >
          <option value="" disabled hidden>
            Select Asset
          </option>
          {assetList.map(asset => {
            return (<option value={asset.id} key={asset.id}>{asset.name} - {assetGroups[asset.assetGroupId].name} </option>)
          })}

    </select>
  )
}

export default AssetSelectInput