// @ts-nocheck
import React, { useState, useEffect } from "react";
import { list } from "aws-amplify/storage";
import { uploadData, downloadData } from "@aws-amplify/storage";
import { resourceTypeEnum, setProcessedModelRenderProperties, extractDateFromFilename } from "./utils";

// Placeholder for now
const colors = [
  "#FF5733", // Vibrant Red-Orange
  "#33FF57", // Lime Green
  "#3357FF", // Bright Blue
  "#FF33A8", // Hot Pink
  "#FFFF33", // Bright Yellow
  "#33FFF6", // Aqua
];

const ProcessedModelDataSourceForm = ({ selectedAssetId, processedModels, dataSourceRecord, onSourceChange, onSubmit }) => {

  const [selectedProcessedModel, setSelectedProcessedModel] = useState(null);
  const [recordList, setRecordList] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const [fileName, setfileName] = useState(null);
  const [variationOptions, setVariationOptions] = useState(null);

  const [dataSourceRecordId] = Object.keys(dataSourceRecord)

  useEffect(() => {
    if (selectedProcessedModel) {
      const submitData = {
        //anything in here triggers re-render from file
        resource: {
          resourceType: resourceTypeEnum.processedModel,
          fileSelected: fileName,
          latest: Boolean(dateSelected === "latest"),
          // latest: Craug continue here
          recordId: selectedProcessedModel.id,
          issued: "no known",
        },
        // anything in here doesn't trigger re-render
        attributes: {
          submited: false,
          name: selectedProcessedModel.label,
          description: selectedProcessedModel.description,
          dataSets: {
            id: 1,
          },
        },
        renderProps: selectedProcessedModel.schema ? setProcessedModelRenderProperties(
          JSON.parse(selectedProcessedModel.schema)) : null,
        data: {
          status: "unfetched",
          schema: JSON.parse(selectedProcessedModel.schema), //use setSensitivityModelRenderProperties BUT we need initial
          values: "",
        },
      };
      onSourceChange({[dataSourceRecordId]:submitData});
    }
  }, [fileName, selectedProcessedModel]);

  const fetchModelList = async (model) => {
    const result = await list({
      path: model.routeLong,
    });
    const items = result.items.map((item) => {
      return { ...item, file: item.path.replace(model.routeLong, "") };
    });
    setRecordList(items)
  }

  useEffect(() => {
    setSelectedProcessedModel(null)
    setRecordList(null)
  },[selectedAssetId])

  const handleProcessedModelChange = async (e) => {
    const model = processedModels.find(
      (element) => element.routeShort === e.target.value
    );
    setSelectedProcessedModel(model);
    fetchModelList(model)
  };

  const handleDateChange = (e) => {
    const newValue = e.target.value
    setDateSelected(newValue);
    let fileNameNew = newValue;
    let latest = false;
    if (newValue === "latest") {
      fileNameNew = sensitivityModel.routeLong;
      latest = true;
    }
    setfileName(fileNameNew);
  };


  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <div className="flex">
      <div className="flex-1">
        <div className="flex-1">
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Processed Models
        </div>
        <div className="flex grow"></div>
      <select
        className="flex grow border p-3 mb-5 text-md rounded-md"
        name="sensitivityModel"
        value={selectedProcessedModel}
        onChange={handleProcessedModelChange}
      >
        <option value="" disabled selected>
          Select one
        </option>
        {processedModels.map((processedModel) => (
          <option
            key={processedModel.routeShort}
            value={processedModel.routeShort}
          >
            {processedModel.label}
          </option>
        ))}
      </select>
      </div>
      <br />
      {recordList !== null ? (
        <div className="flex-1">
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Date Issued
        </div>
        <div className="flex grow"></div>
        <select
          // value={selectedOption} Craig something here
          className="flex grow border p-3 mb-5 text-md rounded-md"
          name="dateIssued"
          onChange={handleDateChange}
        >
          <option value="" disabled>
            Select one
          </option>
          <option value="latest">Most Recent</option>
          {recordList.map((item) => (
            <option key={item.eTag} value={item.path}>
              {extractDateFromFilename(item.file)}
            </option>
          ))}
        </select>
      </div>
      ) : null}
      {/* Craig,  next step allow for checking whether or not you want a certain props, e.g. p20, p30, p50, p70, p90 */}
      {dateSelected && (
        <button className="eai-btn" onClick={handleSubmit}>
          Submit
        </button>
      )}
      </div>
    </div>
  );
};

export default ProcessedModelDataSourceForm;
