// @ts-nocheck
import React from 'react'
import { render } from 'react-dom'

const SensitivityModelStyleUpdate = ({dataSourceRecord, onChange}) => {
    const [id] = Object.keys(dataSourceRecord)
    const record = dataSourceRecord[id]
    const {resource, renderProps} = record
    const { currentVariation, selectedChannel, variations
    } = renderProps

    const handleRenderPropChange = (e) => {
        const updatedRenderProps = structuredClone(renderProps)
        updatedRenderProps[e.target.name] = e.target.value
        
        onChange({
            [id]: {
                ...record,
                renderProps:updatedRenderProps
            }
        })
    }

  return (
    <div>
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Parameter
        </div>
        <select name="currentVariation" value={currentVariation} onChange={handleRenderPropChange} className='m-2 p-2 rounded'>
            {Object.keys(variations).map(variation => {
                return <option key={variation} value={variation}>{variation}</option>
            })
        }
        </select>
        <div className="text-gray-500 text-sm mb-2 pt-2 font-normal font-['Inter'] leading-[18px]">
          Adjustment
        </div>
        <select name="selectedChannel" value={selectedChannel} onChange={handleRenderPropChange} className='m-2 p-2 rounded'>
            {Object.keys(variations[currentVariation]).map(channel => {
                return <option key={channel} value={channel}>{channel}</option>
            })}
            <option value="baseline">baseline</option>
        
        </select>
    </div>
  )
}

export default SensitivityModelStyleUpdate