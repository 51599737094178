// @ts-nocheck
export const blankDataSource = {
  resource: {
    noLoad: true, //don't load if you see this
  },
  attributes: {},
  data: {},
};

export const setSensitivityDataSource = () => {};

export const sensitivityDataSourceSchema = {
  //anything in here triggers re-render
  resource: {
    resourceType: "Sensitivity Model",
    noLoad:false,
    fileSelected: null,
    latest: false,
    recordId: null,
    issued: null,
  },
  // anything in here doesn't trigger re-render
  attributes: {
    name: null,
    description: null,
    dataSets: {
      id: 1,
    },

  },
  data: {
    status: "unfetched",
    schema: null, 
    values: null,
  },
};
