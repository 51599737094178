// @ts-nocheck

import { downloadData } from "@aws-amplify/storage";
import { list } from "aws-amplify/storage";
import { object } from "zod";
import equal from 'fast-deep-equal';

const colorList = [
  // "#000000", // Black
  "#FF0000", // Red
  "#00FF00", // Green
  "#0000FF", // Blue
  "#FFFF00", // Yellow
  "#FFA500", // Orange
  "#800080", // Purple
  "#00FFFF", // Cyan
  "#FFC0CB", // Pink
  "#A52A2A", // Brown
  "#808080", // Gray
  "#FFD700", // Gold
  "#008080", // Teal
  "#00008B", // Dark Blue
  "#8B0000", // Dark Red
  "#006400", // Dark Green
  "#4B0082", // Indigo
  "#FF69B4", // Hot Pink
  "#C0C0C0", // Silver
];

export const resourceTypeEnum = {
  processedModel: "Processed Model",
  sensitivityModel: "Sensitivity Model"
}

export const hasDataChanged = (previousData, newData) => {
  if (!previousData || !newData) {
    return [];
  }
  let datatoFetch = [];

  const previousDataKeys = Object.keys(previousData);
  const newDataKeys = Object.keys(newData);

  // Craig we might not need this
  const newKeys = newDataKeys.filter((key) => {
    return !previousDataKeys.includes(key) && !newData?.[key]?.resource?.noLoad;
  });

  // Find keys in obj1 that are not in obj2 (deleted keys)
  const deletedKeys = previousDataKeys.filter(
    (key) => !newDataKeys.includes(key)
  );

  datatoFetch.push(...newKeys);

  // Checking for Edits
  newDataKeys.forEach((item) => {
    const oldRecord = previousData?.[item];
    const newRecord = newData?.[item];
    if (oldRecord && newRecord) {
      if (JSON.stringify(oldRecord) !== JSON.stringify(newRecord)) {
        datatoFetch.push(item);
      } else {
        console.log("No Change in data fetch")
      }
    }
  });

  return datatoFetch;
};

export const fetchRecordDataSource = async (id, record) => {
  const allCalculatedRecords = [];
  switch (record?.resource?.resourceType) {
    // Craig enum these later
    case "Sensitivity Model":
      return await fetchSensitivityModel(id, record);
    case "Processed Model":
      const doft = await fetchProcessedModel(id, record);
      return doft
    default:
      // Craig return an error
  }
};
const fetchProcessedModel = async (id, record) => {
  try {
    let fileLocation = record?.resource?.fileSelected;
    if (record.resource?.latest) {
      const recordList = await list({
        path: record.resource.fileSelected,
      });
      fileLocation = recordList.items.reduce((highest, item) => {
        return item.path.localeCompare(highest) > 0 ? item.path : highest;
      }, recordList.items[0].path);
    }

    const downloadResult = await downloadData({
      cacheControl: "no-cache",
      path: fileLocation, // Ensure the correct file path is used here
    }).result;

    const jsonData = await downloadResult.body.text();
    const modelData = JSON.parse(jsonData);
    const schema = createSchema(modelData)
    
    const updatedRecord = {
      ...record,
      data: {
        ...record.data,
        status: "loaded",
        value: modelData,
      },
      
    }
  
    if(!equal(record.data.schema,  schema)) {
      updatedRecord.data.schema = schema
      updatedRecord.renderProps = setProcessedModelRenderProperties(schema)
    }

    return updatedRecord
  } catch (error) {
    console.error(`Error fetching data for ${id}:`, error);
    throw error; // Re-throw the error to be handled in Promise.all
  }
};

const fetchSensitivityModel = async (id, record) => {
  try {
    let fileLocation = record?.resource?.fileSelected;
    if (record.resource?.latest) {
      const recordList = await list({
        path: record.resource.fileSelected,
      });
      fileLocation = recordList.items.reduce((highest, item) => {
        return item.path.localeCompare(highest) > 0 ? item.path : highest;
      }, recordList.items[0].path);

    }

    const downloadResult = await downloadData({
      cacheControl: "no-cache",
      path: fileLocation, // Ensure the correct file path is used here
    }).result;

    const jsonData = await downloadResult.body.text();
    const modelData = JSON.parse(jsonData);
    const schema = createSchema(modelData)
    
    const updatedRecord = {
      ...record,
      data: {
        ...record.data,
        status: "loaded",
        value: modelData,
      },
    }
    if(!equal(record.data.schema,  schema)) {
      updatedRecord.data.schema = schema
      updatedRecord.renderProps = setSensitivityModelRenderProperties(schema)
    }


    return updatedRecord
  } catch (error) {
    console.error(`Error fetching data for ${id}:`, error);
    throw error; // Re-throw the error to be handled in Promise.all
  }
};

// For creating schema from processed Models or Sensitivity Model source data
function createSchema(input) {
  const output = {};

  // Iterate over the models object
  for (const [modelKey, modelValue] of Object.entries(input.models)) {
      // console.log("modelKey", modelKey, "modelValue", modelValue)
      const channelsSet = new Set(); // Use a set to collect unique channels

      // Iterate over the data property of the current model
      for (const [_, dataPoint] of Object.entries(modelValue.data)) {
          // Collect all channels (keys of dataPoint)
          for (const channel of Object.keys(dataPoint)) {
              channelsSet.add(channel);
          }
      }

      // Convert the set to an array and assign to the output
      output[modelKey] = Array.from(channelsSet);
  }

  return output;
}

const removeItemFromArray = (array, item) => {
  const index = array.findIndex((element) => element === item);
  if (index !== -1) {
    // Check if the item is found
    array.splice(index, 1); // Remove the item at the found index
  }
  return array;
};

// set Sensitivity Render properties then an adapter function with make sure it's rendered on the graph
export const setSensitivityModelRenderProperties = (renderSchema) => {
  const variations = Object.keys(renderSchema);
  if (variations.includes("baseline")) {
    removeItemFromArray(variations, "baseline");
  } else {
    // we'll want to log this error for editing
    console.log("Error, no baseline!");
  }

  const variationUI = variations.reduce((acc, curr) => {
    const channels = renderSchema[curr];
    return {
      ...acc,
      [curr]: channels.reduce((otherChannels, currentChannel, i) => {
        return {
          ...otherChannels,
          [currentChannel]: {
            color: colorList[i],
            visible: true,
          },
        };
      }, {}),
    };
  }, {});

  const firstVariation = variations[0]
  const Whatisfirst = renderSchema[firstVariation][0]

  return {
    variations: variationUI,
    currentVariation: variations[0],
    format: "singleChannel", //or single channel
    selectedChannel: "baseline",     //Craig handle this for now
  };
};


export const setProcessedModelRenderProperties = (renderSchema) => {

  const copiedColorList = [...colorList]
  const variations = Object.keys(renderSchema);
  if (variations.includes("baseline")) {
    removeItemFromArray(variations, "baseline");
  } else {
    // we'll want to log this error for editing
    console.log("Error, no baseline!");
  }
  // console.log("variations", variations);
  const variationUI = variations.reduce((acc, curr) => {
    const channels = renderSchema[curr];
    return {
      ...acc,
      [curr]: channels.reduce((otherChannels, currentChannel, i) => {
        return {
          ...otherChannels,
          [currentChannel]: {
            color: copiedColorList.pop(),
            visible: true,
          },
        };
      }, {}),
    };
  }, {});
  const firstVariation = variations[0]
  const Whatisfirst = renderSchema[firstVariation][0]
  return {
    variations: variationUI,
  };
};

// get's list of adjusted data sourcs to be rendered on graph
export const dataSourceAdapters = (allDataSources) => {
  const dataSourceList = [];
  Object.keys(allDataSources).forEach((dsKey) => {
    const dataSource = allDataSources[dsKey];
    if (dataSource.data.status !== "loaded") {
      return;
    }
    switch (dataSource?.resource?.resourceType) {
      case "Sensitivity Model":
        const sensitivityAdapted = sensitivityModelAdapter(dataSource);
        dataSourceList.push(...sensitivityAdapted);
        break;
      case "Processed Model":
        const processedAdapted = processedModelAdapter(dataSource);

        dataSourceList.push(...processedAdapted);
        break;
      default:
        console.log("type not found");
    }
  });
  return dataSourceList;
};

// handles sensitivity model
const sensitivityModelAdapter = (dataSourceRecord) => {
  const { renderProps, data } = dataSourceRecord;
  const { variations, currentVariation, format, selectedChannel } = renderProps;
  const {
    value: { models },
  } = data;
  let renderDataItems = {};
  const channelKeys = Object.keys(variations[currentVariation]);

  const variationDetails = models[currentVariation];
  const variationData = variationDetails.data;
  const variationVisuals = renderProps.variations[currentVariation];

  const transformedData = {};
  // Iterate over each datetime property
  for (const [datetime, channels] of Object.entries(variationData)) {
    for (const [channel, value] of Object.entries(channels)) {
      if (!transformedData[channel]) {
        transformedData[channel] = {
          label: `${variationDetails.label} ${channel}`,
          backgroundColor: variationVisuals[channel].color,
          borderColor: variationVisuals[channel].color,
          data: [],
        };
      }
      transformedData[channel].data.push({ x: datetime, y: value });
    }
  }
  // Baseline Data:
  const baseLineDetails = models["baseline"];
  for (const [datetime, channelData] of Object.entries(baseLineDetails.data)) {
    if (!transformedData["baseline"]) {
      transformedData["baseline"] = {
        label: `Baseline`,
        backgroundColor: "#000000",
        borderColor: "#000000",
        data: [],
      };
    }
    transformedData["baseline"].data.push({
      x: datetime,
      y: channelData.value,
    });
  }

  let formattedSensitivityData
  if(format === "singleChannel") {
    formattedSensitivityData = [transformedData[selectedChannel]]
  } else if(format === "allChannels") {
    formattedSensitivityData = Object.values(transformedData)
  }

  return formattedSensitivityData;
};


const processedModelAdapter = (dataSourceRecord) => {
    const { renderProps, data } = dataSourceRecord;
    const { variations } = renderProps;
    const {
      value: { models },
    } = data;
    let renderDataItems = {};

    const transformedData = {};
    Object.keys(variations).forEach((currentVariation) => {
    
    const variationDetails = models[currentVariation];
    const variationData = variationDetails.data;
    const variationVisuals = renderProps.variations[currentVariation];
  
    for (const [datetime, channels] of Object.entries(variationData)) {
      for (const [channel, value] of Object.entries(channels)) {
          if(!variationVisuals[channel].visible) {
            continue
          }
        const dataSetId = `${currentVariation}-${channel}`
        if (!transformedData[dataSetId]) {
          transformedData[dataSetId] = {
            label: `${variationDetails.variation} ${channel}`,
            backgroundColor: variationVisuals[channel].color,
            borderColor: variationVisuals[channel].color,
            data: [],
          };
        }
        transformedData[dataSetId].data.push({ x: datetime, y: value });
      }
    }
  })

    let formattedSensitivityData = Object.values(transformedData)
    
    return formattedSensitivityData;
  };



export const extractDateFromFilename = (filePath) => {
  // Define a regex to match the file name and capture the date
  const regex = /(\d{4}-\d{2}-\d{2}-\d{2})-[^/]+\.json$/;
  
  // Test the regex and extract matches
  const match = filePath.match(regex);
  
  if (match && match[1]) {
      const datePart = match[1]; // Captured date string (YYYY-MM-DD-HH)
      return datePart;
  }
  
  // Return null or handle cases where the regex doesn't match
  return null;
}