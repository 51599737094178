// @ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { setModalOpen, modalViewsConst } from "../../store/modalSlice";
import PointMapForm from "./PointMapForm";
import AssetCsvUpload from "./AssetCsvUpload";
import EditAssets from "./EditAssets";
import AssetAlertForm from "./AssetAlertForm";
import BrandNewModals from "./BrandNewModals";
import NewAssetGroupModal from "./NewAssetGroupModal";
import NewAssetModal from "./NewAssetModal";
import { AddGroupFirst } from "./AddGroupFirst";
import DeleteApprove from "./DeleteApprove";
import NewAlertModal from "./NewAlertModal";
import ManageAssetAlertLite from "./ManageAssetAlertLite";
import PerformanceCurveForm from "./PerformanceCurveForm";
import PCurveGraph from "../PerformanceCurve/PCurveGraph";
import AssetPCurveEdit from "./AssetPCurveEdit";
import Notification from "./Notification";
import LoggedInResetPassword from "./LoggedInResetPassword";
import PCurveDisplay from "./PCurveDisplay";
import ReactGA from "react-ga4";
import { record } from "aws-amplify/analytics";
import SolarPowerForm from "./SolarPowerForm";
import EnergyCurveSelect from "./EnergyCurveSelect";
import SolarModelEnergyDisplay from "./SolarModelEnergyDisplay";
import ProcessModelDownload from "./ProcessModelDownload";
import ChangeProfilePicture from "./ChangeProfilePicture";

const customStyles = {
  content: {
    width: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const customOverlay = {
  content: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
};

Modal.setAppElement("#modalAnchor");
const ModalGlobal = () => {
  const modalState = useSelector((state) => state.modalState);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(setModalOpen(false)); // Clicked outside the div
      }
    }
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 540); // 640px is an example breakpoint for small devices, adjust as needed
    };
    checkScreenSize();

    // Bind the event listener
    window.addEventListener("resize", checkScreenSize);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [modalRef]);

  useEffect(() => {
    if (modalState.modalOpen && modalState.modalView) {
      record({
        name: "openModal",
        attributes: {
          view: modalState.modalView,
        },
      });

      ReactGA.event({
        category: "User",
        action: "Open Modal",
        value: modalState.modalView,
      });
    }
  }, [modalState.modalOpen]);

  return (
    <Modal
      isOpen={modalState.modalOpen}
      // onAfterOpen={afterOpenModal}
      // onRequestClose={closeModal}
      style={{
        content: {
          zIndex: 1000,
          width: "100%",
          height: "100%",
          height: isSmallScreen ? "100%" : "auto",
          width: isSmallScreen
            ? "100%"
            : modalState.modalView === modalViewsConst.addAssetForm
            ? "25%"
            : "75%",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "0",
          border: "1px solid #BDBDBD",
          borderRadius: "8px",
          maxWidth:
            modalState.modalView === modalViewsConst.processedModelDownload
              ? `1110px`
              : "710px",
        },
      }}
      contentLabel="Example Modal"
      overlayClassName={customOverlay}
    >
      <div ref={modalRef} className="max-h-[80vh] p-5">
        {/* {modalState.modalView === modalViewsConst.pointMapForm && (
          <PointMapForm initialPoint={modalState.properties} />
        )} */}
        {/* {modalState.modalView === modalViewsConst.addAssetForm && (
          <PointMapForm />
        )} */}
        {/* {modalState.modalView === modalViewsConst.editAssetForm && (
          <EditAssets />
        )} */}
        {/* {modalState.modalView === modalViewsConst.uploadAssetForm && (
          <AssetCsvUpload />
        )} */}
        {modalState.modalView === modalViewsConst.addAssetAlertForm && (
          <AssetAlertForm />
        )}
        {modalState.modalView === modalViewsConst.editAssetAlertForm && (
          <AssetAlertForm
            editFlag={true}
            initialAlert={modalState.properties}
          />
        )}
        {modalState.modalView === modalViewsConst.addAssetGroup && (
          <NewAssetGroupModal />
        )}
        {modalState.modalView === modalViewsConst.editAssetGroup && (
          <NewAssetGroupModal
            editFlag={true}
            initialAssetGroup={modalState.properties}
          />
        )}
        {modalState.modalView === modalViewsConst.addAssetNEW && (
          <NewAssetModal initialAssetState={modalState.properties} />
        )}
        {modalState.modalView === modalViewsConst.editAssetNEW && (
          <NewAssetModal
            editFlag={true}
            initialAssetState={modalState.properties}
          />
        )}
        {modalState.modalView === modalViewsConst.addGroupFirst && (
          <AddGroupFirst />
        )}
        {modalState.modalView === modalViewsConst.deleteAssetModal && (
          <DeleteApprove
            data={modalState.properties}
            deleteType="asset"
            deleteTypeLabel="asset"
          />
        )}
        {modalState.modalView === modalViewsConst.deleteAssetGroup && (
          <DeleteApprove
            data={modalState.properties}
            deleteType="assetGroup"
            deleteTypeLabel="asset Group"
          />
        )}
        {modalState.modalView === modalViewsConst.deletePerformanceCurve && (
          <DeleteApprove
            data={modalState.properties}
            deleteType="pCurve"
            deleteTypeLabel="Wind Energy Model"
          />
        )}
        {modalState.modalView === modalViewsConst.deleteSolarEnergyModel && (
          <DeleteApprove
            data={modalState.properties}
            deleteType="SolarEnergyModel"
            deleteTypeLabel="Solar Energy Model"
          />
        )}

        {modalState.modalView === modalViewsConst.addNewAlert && (
          <NewAlertModal groupId={modalState.properties} />
        )}
        {modalState.modalView === modalViewsConst.editNewAlert && (
          <NewAlertModal
            groupId={modalState.properties.assetGroupId}
            initialAlertState={modalState.properties}
            editFlag={true}
          />
        )}
        {modalState.modalView === modalViewsConst.manageAssetAlertLite && (
          <ManageAssetAlertLite
            groupid={modalState.properties.groupid}
            assetid={modalState.properties.assetid}
          />
        )}
        {modalState.modalView === modalViewsConst.addPerformanceCurve && (
          <PerformanceCurveForm />
        )}
        {modalState.modalView === modalViewsConst.editPerformanceCurve && (
          <PerformanceCurveForm
            editFlag={true}
            initialPCurve={modalState.properties}
          />
        )}
        {modalState.modalView === modalViewsConst.powerCurveGraph && (
          <PCurveDisplay graphProperties={modalState.properties} />
        )}
        {modalState.modalView === modalViewsConst.solarEnergyModelPreview && (
          <SolarModelEnergyDisplay modelProperties={modalState.properties} />
        )}
        {modalState.modalView === modalViewsConst.assetPowerCurveEdit && (
          <AssetPCurveEdit asset={modalState.properties} />
        )}
        {modalState.modalView === modalViewsConst.notification && (
          <Notification
            header={modalState?.properties?.header}
            details={modalState?.properties?.details}
          />
        )}
        {modalState.modalView === modalViewsConst.passwordChange && (
          <LoggedInResetPassword />
        )}
        {modalState.modalView === modalViewsConst.newSolarPowerCurve && (
          <SolarPowerForm />
        )}
        {modalState.modalView === modalViewsConst.energyModelSelect && (
          <EnergyCurveSelect />
        )}
        {modalState.modalView === modalViewsConst.processedModelDownload && (
          <ProcessModelDownload
            asset={modalState.properties.asset}
            assetOwner={modalState.properties.assetOwner}
          />
        )}
        {modalState.modalView === modalViewsConst.changeProfilePicture && (
          <ChangeProfilePicture />
        )}
      </div>
    </Modal>
  );
};

export default ModalGlobal;
