// @ts-nocheck
import React, { useEffect } from "react";
import { ReactComponent as LightningIcon } from "../assets/icons/lightning.svg";
import { ReactComponent as BarLineChart } from "../assets/icons/bar-line-chart.svg";
import {
    HeadBreadCrumb,
    NestedBreadCrumbs,
  } from "../components/AssetBreadCrumb";
import { modalViewsConst, setModalState } from "../store/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchPCurves, fetchSolarEnergyModels } from "../store/pCurveSlice";
import { PCurveTable } from "../components/PerformanceCurve/PCurveTable";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
    { route: "/home/dataupload", label: "Analysis Models" },
  ];

const AnalysisListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pCurve = useSelector((state) => state.pCurve);


  const AddNewAnalysis = () => {
    navigate("/home/analysis/new")
  };

  return (
    <>
      <div className="flex-1 flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow">
        <NestedBreadCrumbs items={breadcrumbItems}>
            <BarLineChart
              stroke="#6B7280"
              className="w-5 h-5 ml-2 mr-1 cursor-pointer"
            />
          </NestedBreadCrumbs>
          <div className="flex flex-row h-24 border-y border-gray-200 p-5 justify-left items-center">
            <div className="flex grow items-center">
              <div className="w-12 h-12 border border-gray-200 rounded-lg flex items-center shrink-0 justify-center mr-3">
                <BarLineChart stroke="#A2BBBF" />
              </div>
              <div className="flex-col items-center justify-center">
                <div className="text-gray-900 text-xl font-semibold font-Inter mb-1">
                  Analysis Records
                </div>
                <div className="text-gray-500 text-sm font-normal font-Inter ml-1">
                  Evaluate Forecast Performance and `PLACEHOLDER`
                </div>
              </div>
            </div>
            <button
              onClick={AddNewAnalysis}
              className="flex-wrap eai-btn-grn my-1"
              type="submit"
            >
              <div className="mx-3">
                <span className="hidden md:block">+ Start New Analysis</span>
                <span className="block md:hidden">+</span>
              </div>
            </button>
          </div>
          <div className="flex grow p-5 h-[calc(100vh_-_9rem)] overflow-y-auto">
            <div className="w-full bg-white overflow-x-auto border border-gray-200 rounded-md">
                <div className="text-center">No data yet</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalysisListPage