// @ts-nocheck
import React from 'react'
import ProfilePictureRounded from '../ProfilePictureRounded'

const OrganizationPreview = ({ organization}) => {
  const { members } = organization
  
  return (
    <div className='flex '>
        <div>
        {/* {organization.name} */}
        </div>
            <div className="flex grow h-10 ">
              {members.length > 2 &&
                <div className="w-8 h-8  rounded-[100%] ">
                  <ProfilePictureRounded className={"border-white"} pictureURL={members?.[2]?.profilePictureUrl} diameter={32} />
                </div>
              }
              {members.length > 1 &&
                <div className="w-8 h-8 ml-[-10px] rounded-[100%] ">
                <ProfilePictureRounded className={"border-white"} pictureURL={members?.[1]?.profilePictureUrl} diameter={32} />
                </div>
              } 
                <div className="w-8 h-8 ml-[-10px] rounded-[100%] mb-1">
                <ProfilePictureRounded className={"border-white"} pictureURL={members?.[0]?.profilePictureUrl} diameter={32} />
                </div>
                {/* <img
                className="w-8 h-8 ml-[-10px] rounded-[100px] border-2 border-white"
                src="https://picsum.photos/32/32?random=2"
                />
                <img
                className="w-8 h-8 ml-[-10px] rounded-[100px] border-2 border-white"
                src="https://picsum.photos/32/32?random=3"
                /> */}
          </div>
            
    </div>
  )
}

export default OrganizationPreview