// @ts-nocheck
import React, { useEffect, useState } from "react";
// import { Auth } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";

const withAdminAuthComponent = (Component) => {
  return (props) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [checkedAuth, setCheckedAuth] = useState(false);

    useEffect(() => {
      const checkAdminGroup = async () => {
        try {
          const authSession = await fetchAuthSession();
          const groups =
            authSession?.tokens?.idToken?.payload?.["cognito:groups"] ?? [];
          if (groups.includes("Admin")) {
            setIsAdmin(true);
          }
        } catch (error) {
          console.error("Error checking admin group", error);
        } finally {
          setCheckedAuth(true);
        }
      };

      checkAdminGroup();
    }, []);

    // Render nothing until authentication is checked
    if (!checkedAuth) {
      return null;
    }

    return isAdmin ? <Component {...props} /> : null;
  };
};

export default withAdminAuthComponent;