// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setModalOpen } from "../../store/modalSlice";
import { ReactComponent as GroupIcon } from "../../assets/icons/group-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import {
  postAssetGroup,
  editAssetGroupFunc,
} from "../../store/assetGroupSlice";
import { z } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { uploadData } from "@aws-amplify/storage";
import { getOwnerFromId } from "../../util/utils";
import { v4 as uuidv4 } from "uuid";

const AssetGroupNameSchema = z.object({
  name: z.string().trim().min(2).max(50),
});
type AssetGroupNameType = z.infer<typeof AssetGroupNameSchema>;

const NewAssetGroupModal = ({ editFlag = false, initialAssetGroup }) => {
  const [formStep, setFormStep] = useState(1);
  const [file, setFile] = useState(null);
  const [owner, setOwner] = useState(null)
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AssetGroupNameType>({
    resolver: zodResolver(AssetGroupNameSchema),
    mode: "onChange",
  });
  const onSubmit: SubmitHandler<SignUpSchemaType> = async (data) => {
    let nextStep = (formStep + 1) % 2 === 0 ? 2 : 1;

    if (editFlag) {
      let updatedAssetGroup = { id: initialAssetGroup.id, ...data }
      if(file) {
        const fileKey = await uploadFile(initialAssetGroup.id, initialAssetGroup.organizationId)
        updatedAssetGroup["profilePictureUrl"] = fileKey
      }
      if(owner) {
        updatedAssetGroup["owner"] = owner.id
      }
      await dispatch(editAssetGroupFunc(updatedAssetGroup));
    } else {
      const newID = uuidv4();
      let newAssetGroup = { id:newID, ...data }

      // CRAIG COMMENT OUT FOR NOW, we need the organization ID in order to upload photo on Add new Group - mark as bug
      // if(file) {
      //   const fileKey = await uploadFile(newID)
      //   newAssetGroup["profilePictureUrl"] = fileKey
      // }
      if(owner) {
        newAssetGroup["owner"] = owner.id
      }
      //   setFormStep(nextStep);
      await dispatch(postAssetGroup(newAssetGroup));
    }
    dispatch(setModalOpen(false));
  };

  // Up next upload
  const uploadFile = async (assetGroupId, organizationId) => {
    if (!file) return null;
    try {
        const result = await uploadData({
          path: ({identityId}) => {
              let filePath
              if(organizationId) {
                // asset belongs to an organization
                filePath = `organizationprotected/${organizationId}/assets/${assetGroupId}/profilePic/${file.name}/`
              } else {
                // non-organization asset
                let ownerCogId = owner?.cognitoIdentityId ?? identityId
                filePath = `protected/${ownerCogId}/assetGroups/${assetGroupId}/profilePic/${file.name}`     
              }

            return filePath
          },
          data: file,
          options: {
            // onProgress // Optional progress callback.
          }
        }).result;
        console.log("result", result)
      return result.path; // Return the file's S3 location key
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const getOwner = async () => {
    const assetGroupOwner = await getOwnerFromId(initialAssetGroup.owner)
    console.log("owner", assetGroupOwner)
    setOwner(assetGroupOwner)
    
  }

  useEffect(() => {
    if (editFlag) {
      setValue("name", initialAssetGroup.name);
      getOwner()
    }
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleCancel = () => {
    dispatch(setModalOpen(false));
  };

  return (
    <div className="">
      <div
        onClick={() => dispatch(setModalOpen(false))}
        className=" flex flow-row grow items-center border-b border-gray-200 p-5"
      >
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <GroupIcon className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-600 text-md font-normal font-Inter mr-3 justify-end">
          Step 1 of 2
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>

      <div className="h-1 flex grow">
        <div className="h-1 w-1/2 bg-primary-500"></div>
      </div>
      <div className="flex flex-col grow p-5">
        <div className="text-gray-900 text-xl font-semibold mb-2 font-Inter">
          {editFlag ? "Existing" : "New"} Asset Group
        </div>
        <div className="text-gray-400 text-md mb-5 font-normal font-Inter">
          Please enter a name for this group
        </div>
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px] flex justify-between">
          <span>Group Name</span>
          {errors.name ? <span>{errors.name.message}</span> : <span></span>}
        </div>
        {/* <div className="flex grow"> */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="asset-group-name-form"
        >
          <div className="flex grow">
            <input
              // name="name"
              {...register("name")}
              className="flex grow border p-3 text-md rounded-md"
            />
          </div>
          {editFlag &&
<>
          <br />
          <label
          htmlFor="profile-photo"
          className="block text-gray-500 text-sm font-medium mb-2"
          >
            Choose an optional profile photo
          </label>
          <input
          id="profile-photo"
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 hover:cursor file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary-50 file:text-primary-700 hover:file:bg-primary-100"
          />
          </>
        }
        </form>
      </div>
      <div className="flex flex-row grow space-x-3 p-5">
        <button className="flex-grow eai-btn my-1" type="submit">
          <div className="mx-3" onClick={handleCancel}>
            Cancel
          </div>
        </button>
        <button
          // onClick={() => handleSubmit()}
          className="flex-grow eai-btn-grn my-1"
          type="submit"
          form="asset-group-name-form"
        >
          <div className="mx-3">{editFlag ? "Update" : "Create"}</div>
        </button>
      </div>
    </div>
  );
};

export default NewAssetGroupModal;
