export const assetAlertsByAssetGroupIdAndAssetIdCustom = /* GraphQL */ `
  query AssetAlertsByAssetGroupIdAndAssetId(
    $assetGroupId: ID!
    $assetId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetAlertsByAssetGroupIdAndAssetId(
      assetGroupId: $assetGroupId
      assetId: $assetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        occurenceKey
        name
        description
        rainfall
        period
        window
        assetId
        asset {
          id
          name
        }
        emails
        phoneNumbers
        active
        groupAlert
        lastRun
        dataSource
        probability
        assetGroupId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

//CRAIG newHire delete this
export const listAssetAlertsCustom = /* GraphQL */ `
  query ListAssetAlerts(
    $filter: ModelAssetAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        occurenceKey
        name
        description
        rainfall
        period
        window
        assetId
        asset {
          id
          name
        }
        emails
        phoneNumbers
        active
        groupAlert
        dataSource
        probability
        lastRun
        assetGroupId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listPerformanceCurvesCustom = /* GraphQL */ `
  query ListPerformanceCurves(
    $filter: ModelPerformanceCurveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPerformanceCurves(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        curveData {
          #need this to get curve data
          x
          y
        }
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const geoPointsByAssetGroupIdCustom = /* GraphQL */ `
  query GeoPointsByAssetGroupId(
    $assetGroupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGeoPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    geoPointsByAssetGroupId(
      assetGroupId: $assetGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        lat
        lng
        client
        elevation
        organizationId
        processedModels {
        items {
          id
          label
          routeShort
          routeLong
          displayType
          modelType
          schema
        }
      }
        description
        assetGroupId
        precipCurveId
        windCurveId
        windCurve {
          id
          name
          description
          curveData {
            x
            y
          }
          xUnit
          yUnit
          createdAt
          updatedAt
          owner
          __typename
        }
        solarCurveId
        solarCurve {
          id
          name
          description
          xUnit
          yUnit
          dcCap
          acCap
          panelEfficiency
          thermalLoss
          minIrradiance
          trackingType
          arrayTilt
          arrayAzimuth
          maxTilt
          groundCoverRatio
        }
        createdAt
        updatedAt
        userGeoPointsId
        owner
        value {
          value
          currency
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

// Hopefully delete this later
// export const listUsersCustom = /* GraphQL */ `
//   query ListUsers(
//     $filter: ModelUserFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
//       items {
//         id
//         username
//         email
//         name
//         createdAt
//         updatedAt
//         organizations
//         cognitoIdentityId
//         profilePictureUrl
//         assetGroupCount  
//         assetCount
//         __typename
//       }
//       nextToken
//       __typename
//     }
//   }
// `;
export const getUserCustom = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      name
      profilePictureUrl
      cognitoIdentityId
      assetGroupCount
      assetCount
      createdAt
      updatedAt
      __typename
    }
  }
`;

// gets all assetGroups and nested Assets
export const fetchGroupsAndNestedAssetsCustom = /* GraphQL */ `
  query ListAssetGroups(
    $filter: ModelAssetGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        profilePictureUrl
        description
        createdAt
        updatedAt
        organizationId
        userAssetGroupsId
        assets {
          items {
        id
        name
        lat
        lng
        client
        elevation
        organizationId
        processedModels {
        items {
          id
          label
          routeShort
          routeLong
          displayType
          modelType
          schema
        }
      }
        description
        assetGroupId
        precipCurveId
        windCurveId
        windCurve {
          id
          name
          description
          curveData {
            x
            y
          }
          xUnit
          yUnit
          createdAt
          updatedAt
          owner
          __typename
        }
        solarCurveId
        solarCurve {
          id
          name
          description
          xUnit
          yUnit
          dcCap
          acCap
          panelEfficiency
          thermalLoss
          minIrradiance
          trackingType
          arrayTilt
          arrayAzimuth
          maxTilt
          groundCoverRatio
        }
        createdAt
        updatedAt
        userGeoPointsId
        owner
        value {
          value
          currency
        }
        __typename
      }
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listOrganizationsCustom = `query MyQuery {
  listOrganizations {
    items {
      name
      owner
      profilePictureUrl
      description
      id
      members {
        items {
          user {
            email
            name
            username
            profilePictureUrl
          }
        }
      }
    }
  }
}`

export const listOrganizationAndMembersCustom = /* GraphQL */  `query MyQuery {
  listOrganizations {
    items {
      description
      id
      name
      owner
      profilePictureUrl
    }
  }
  listUserOrganizations {
    items {
      organizationId
      user {
        name
        organizationIds
        profilePictureUrl
        username
        id
        cognitoIdentityId
        email
      }
    }
  }
}`