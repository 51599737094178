// @ts-nocheck
import axios from "axios";
import { get, put, del, post } from "aws-amplify/api";
import { calculatePercentile, formatAnnualData } from "../util/utils";
import syntheticEvent from "./syntheticEvent.json";

const myInit = (data) => {
  // const body = { body:data } ?? {}
  return {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    // ...body
  };
  // body: {
  //   name: "CraigTest4Ever",
  // },
};

export const myTInit = (data) => {
  return {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: {
      ...data,
    },
    // ...body
  };
};

export const fetchDataSources = async () => {
  const restOperation = get({
    apiName: "datasourceapi",
    // path: "/datasource/1/records",
    path: "/datasource/datasources",
  });
  try {
    const operationResponse = await restOperation.response;
    const val = await operationResponse.body.json();
    return await val;
  } catch (e) {
    console.log("e", e);
  }
};

export const fetchDataSourcesRecord = async () => {
  const restOperation = get({
    apiName: "datasourceapi",
    // path: "/datasource/1/records",
    path: "/datasource/1/records",
  });
  try {
    const operationResponse = await restOperation.response;
    const val = await operationResponse.body.json();
    return await val;
  } catch (e) {
    console.log("e", e);
  }
};

export const updateCogId = async (api, route) => {
  const restOperation = post({
    apiName: "authenticatecogapi",
    path: "/user",
  });
  try {
    const operationResponse = await restOperation.response;
    const val = await operationResponse.body.json();
    return await val;
  } catch (e) {
    console.log("e", e);
  }
};

//CRAIG CHANGE to Frontend function later
export const fetchGraphData = async (annualData) => {
  const formattedData = formatAnnualData(annualData);
  const test = myTInit(formattedData);
  const restOperation = post({
    apiName: "statsapi",
    path: "/todo",
  });
  return await restOperation.response;
};

export const fetchSolarEnergyForecast = async (data) => {
  try {
    const restOperation = post({
      apiName: "datascienceapi",
      path: "/api/solarenergymodel",
      options: {
        body: data,
      },
    });

    const operationResponse = await restOperation.response;
    const { response } = await operationResponse.body.json();
    const solarEnergyRaw = response.gen;

    const formattedSolarEnegyForecast = formatRawSolarInputs(
      data,
      solarEnergyRaw
    );
    return formattedSolarEnegyForecast;
  } catch (e) {
    console.log("fetchSolarerror", e);
  }
};

//thefw (t hour en f wor)
//ohhfc (o hour h f con)
export const getOHHFC = async (lat, lng) => {
  const restOperation = post({
    apiName: "datascienceapi",
    path: "/api/forecast",
    options: {
      body: { lat, lng, model: "ohhfc", forecast: "all" },
    },
  });

  const operationResponse = await restOperation.response;
  const { response } = await operationResponse.body.json();
  const { data, dateTimef } = JSON.parse(response.body);
  const hourArray = new Array(48).fill(1).map((_, i) => (i + 1) * 1); //1 hour forecast hours

  const precipFormatData = formatArrayData(data.precip, dateTimef, hourArray);
  const precipAccumulatedData = cumulativeCalc(precipFormatData);
  const solarLFormatData = formatArrayData(data.solarL, dateTimef, hourArray);
  const solarSFormatData = formatArrayData(data.solarS, dateTimef, hourArray);
  const tempFormatData = formatArrayData(data.temp, dateTimef, hourArray);
  const humidityFormatData = formatArrayData(data.humidr, dateTimef, hourArray);
  const [wind10Mangitude, wind10Direction] = formatWindArrayData(
    data.windu10,
    data.windv10,
    dateTimef,
    hourArray
  );
  const [wind80Mangitude, wind80Direction] = formatWindArrayData(
    data.windu80,
    data.windv80,
    dateTimef,
    hourArray
  );
  const solarEnergyWeatherDataInputs = formatSolarEnergyInputsOHHFC(
    dateTimef,
    hourArray,
    data.temp,
    wind10Mangitude.map((val) => val.y),
    data.solarS
  );

  return {
    precip: {
      Hourly: precipFormatData,
      Cumulative: precipAccumulatedData,
    },
    solar: {
      solarL: solarLFormatData,
      solarS: solarSFormatData,
    },
    wind: {
      wind10: {
        Speed: wind10Mangitude,
        Direction: wind10Direction,
      },
      wind80: {
        Speed: wind80Mangitude,
        Direction: wind80Direction,
      },
    },
    temp: tempFormatData,
    humidity: humidityFormatData,
    solarEnergyWeatherDataInputs,
  };
};

// Format data. pass in array, start time and hourly increase, converts to datetime and
const formatArrayData = (dataArrayRaw, submitTime, hourArray) => {
  const dataArray = [...dataArrayRaw];
  const [year, month, day, hour] = submitTime.split("-");
  const recordStartDate = new Date(Date.UTC(year, month - 1, day, hour));

  const formattedData = [];
  hourArray.forEach((hourKey, i) => {
    const millisecondsToAdd = parseInt(hourKey) * 60 * 60 * 1000;
    var newTime = recordStartDate.getTime() + millisecondsToAdd;
    const newTimeDate = new Date(newTime);
    formattedData.push({ y: dataArray[i], x: newTimeDate });
  });

  return formattedData;
};

const formatWindArrayData = (
  dataArrayRawU,
  dataArrayRawV,
  submitTime,
  hourArray
) => {
  const dataArrayU = [...dataArrayRawU];
  const dataArrayV = [...dataArrayRawV];
  const [year, month, day, hour] = submitTime.split("-");
  const recordStartDate = new Date(Date.UTC(year, month - 1, day, hour));

  const formattedMagnitudeData = [];
  const formattedDirectionData = [];
  hourArray.forEach((hourKey, i) => {
    const windu = dataArrayU[i];
    const windv = dataArrayV[i];
    const vector1 = windu ** 2;
    const vector2 = windv ** 2;

    const millisecondsToAdd = parseInt(hourKey) * 60 * 60 * 1000;
    var newTime = recordStartDate.getTime() + millisecondsToAdd;
    const newTimeDate = new Date(newTime);

    // Magnitude
    const magnitude = Math.sqrt(vector1 + vector2);
    formattedMagnitudeData.push({ y: magnitude, x: newTimeDate });

    // Direction
    let angleRadians = Math.atan2(windv, windu);
    angleRadians += Math.PI;
    let angleDegrees = angleRadians * (180 / Math.PI);
    angleDegrees = (angleDegrees + 360) % 360;
    formattedDirectionData.push({ y: angleDegrees, x: newTimeDate });
  });
  return [formattedMagnitudeData, formattedDirectionData];
};

const formatSolarEnergyInputsOHHFC = (
  submitTime,
  hourArray,
  temperature,
  magnitudeWind,
  solarShort
) => {
  const [year, month, day, hour] = submitTime.split("-");
  const recordStartDate = new Date(Date.UTC(year, month - 1, day, hour));
  const hourUTCArray = hourArray.map((hourKey) =>
    hoursAhead(recordStartDate, hourKey)
  );

  const dateTimeUTCArray = [];
  const tempArray = [];
  const windArray = [];
  const ghiArray = [];
  hourUTCArray.forEach((hourKey, i) => {
    dateTimeUTCArray.push(hourKey);
    tempArray.push(parseFloat(temperature[i].toFixed(3)));
    windArray.push(parseFloat(magnitudeWind[i].toFixed(3)));
    ghiArray.push(parseFloat(solarShort[i].toFixed(3)));
  });
  return {
    datetime: dateTimeUTCArray,
    temp: tempArray,
    wind: windArray,
    ghi: ghiArray,
  };
};

// Format data so that solar energy power can be calculated
// data has to be organized by scenario e.g. temp, wind magnitude and solar short wave must calclulated
const formatSolarEnergyInputsTHEFW = (
  submitTime,
  hourArray,
  temperature,
  magnitudeWind,
  solarShort
) => {
  const [year, month, day, hour] = submitTime.split("-");
  const recordStartDate = new Date(Date.UTC(year, month - 1, day, hour));
  const hourUTCArray = hourArray.map((hourKey) =>
    hoursAhead(recordStartDate, hourKey, 1.5)
  );
  return solarShort[0].reduce((acc, _, i) => {
    const dateTimeUTCArray = [];
    const tempArray = [];
    const windArray = [];
    const ghiArray = [];
    hourUTCArray.forEach((dt, j) => {
      dateTimeUTCArray.push(dt);
      tempArray.push(parseFloat(temperature[j][i].toFixed(3)));
      windArray.push(parseFloat(magnitudeWind[j][i].toFixed(3)));
      ghiArray.push(parseFloat(solarShort[j][i].toFixed(3)));
    });
    let ensembleId = i + 1;
    if (ensembleId === 31) {
      ensembleId = "avg";
    } else if (ensembleId === 32) {
      ensembleId = "ctrl";
    }
    return {
      ...acc,
      [ensembleId]: {
        datetime: dateTimeUTCArray,
        temp: tempArray,
        wind: windArray,
        ghi: ghiArray,
      },
    };
  }, {});
};

// This is for Solar Energy Output, but may need adjusting for utc? ask Cody
// hour subtract is for solar adjusting average
const hoursAhead = (recordStartDate, hour, hourSubtract = 0) => {
  const millisecondsToAdd =
    parseInt(hour) * 60 * 60 * 1000 - hourSubtract * 60 * 60 * 1000;
  const newTime = recordStartDate.getTime() + millisecondsToAdd;
  return new Date(newTime).toISOString().slice(0, 16); //Check with Cody, ISO?
};

// get wind @80 m
export const getTHEFWwind80 = async (lat = 49.26, lng = -123.05) => {
  const restOperation = post({
    apiName: "datascienceapi",
    path: "/api/forecast",
    options: {
      body: { lat, lng, forecast: "gefs50", model: "thefw" },
    },
  });

  const operationResponse = await restOperation.response;
  const { response } = await operationResponse.body.json();
  const { data, dateTimef } = JSON.parse(response.body);

  const forecastData = data;
  const hourArray = new Array(data.wind80u.length)
    .fill(1)
    .map((e, i) => (i + 1) * 3);

  const windFormatData = formatWindData(
    forecastData.wind80u,
    forecastData.wind80v,
    dateTimef,
    hourArray
  );

  return windFormatData;
};

//CRAIG CHANGE to Frontend function later, maybe some try / catch?
export const getTHEFW = async (lat = 49.26, lng = -123.05) => {
  try {
    const restOperation = post({
      apiName: "datascienceapi",
      path: "/api/forecast",
      options: {
        body: { lat, lng, forecast: "all", model: "thefw" },
      },
    });
    const operationResponse = await restOperation.response;
    const { response } = await operationResponse.body.json();
    const { data, dateTimef } = JSON.parse(response.body);
    const forecastData = data;
    const hourArray = new Array(80).fill(1).map((e, i) => (i + 1) * 3); //3 hour forecast hours
    const precipFormData = formatPrecipData(
      forecastData.precip,
      dateTimef,
      hourArray
    );
    
    const temperatureCloned = JSON.parse(JSON.stringify(forecastData.temp));
    const solarTempData = JSON.parse(JSON.stringify(forecastData.temp))
    const tempFormData = formatInterpolateParam(temperatureCloned, dateTimef, hourArray) //using precip for now
    const humidityFormData = formatInterpolateParam(forecastData.humidr, dateTimef, hourArray) //using precip for now

    const windFormatData = formatWindData(
      forecastData.windu,
      forecastData.windv,
      dateTimef,
      hourArray
    );
    const solarShort = JSON.parse(JSON.stringify(forecastData.solarS));
    const solarFormatData = formatSolar(
      forecastData.solarS,
      forecastData.solarL,
      dateTimef,
      hourArray
    );
    const solarEnergyWeatherDataInputs = formatSolarEnergyInputsTHEFW(
      dateTimef,
      hourArray,
      solarTempData,
      Object.values(windFormatData.hourMagEnsemble),
      solarShort
    );
    return {
      precip: precipFormData,
      wind: windFormatData,
      solar: solarFormatData,
      dateTime: dateTimef,
      temperature: tempFormData,
      humidity:humidityFormData,
      solarEnergyWeatherDataInputs,
      dateTimef,
    };
  } catch (e) {
    console.log("error fetching world query", e);
  }
};

// Craig Move
const interpolatExpandArray = (data) => {
  let result = [];

  data.forEach((item, index) => {
    const yValue = item.y;
    const startTime = new Date(item.x);
    startTime.setMinutes(startTime.getMinutes() - 30); // Adjust for 90 minutes backwards

    for (let i = 0; i < 3; i++) {
      let newTime = new Date(startTime);
      newTime.setHours(startTime.getHours() + i);

      result.push({
        y: yValue,
        x: newTime.toISOString().slice(0, 19) + ".000Z",
      });
    }
  });

  return result;
};

const formatSolar = (solarSRaw, solarLRaw, submitTime, hourArray) => {
  const solarS = [...solarSRaw];
  const solarL = [...solarLRaw];
  const [year, month, day, hour] = submitTime.split("-");
  const recordStartDate = new Date(Date.UTC(year, month - 1, day, hour));
  const percentiles = [10, 30, 50, 70, 90];
  const hours = hourArray;
  const percentilesLWave = percentiles.reduce(
    (acc, curr) => ({ ...acc, [`p${curr}`]: [] }),
    {}
  );
  const percentilesSWave = percentiles.reduce(
    (acc, curr) => ({ ...acc, [`p${curr}`]: [] }),
    {}
  );

  const controlLong = [];
  const controlShort = [];
  const averageCollectedLong = [];
  const averageCollectedShort = [];
  const averageCalcLong = [];
  const averageCalcShort = [];
  const hoursUTC = [];

  hours.forEach((hourKey, i) => {
    const solarLData = solarL[i];
    const solarSData = solarS[i];
    const millisecondsToAdd =
      parseInt(hourKey) * 60 * 60 * 1000 - 90 * 60 * 1000; //Offesttime 90 minutes
    const newTime = recordStartDate.getTime() + millisecondsToAdd;
    hoursUTC.push(new Date(newTime));
    const newTimeDate = new Date(newTime);

    const hourCtrlL = solarLData.pop();
    const hourCtrlS = solarSData.pop();
    const hourAvgL = solarLData.pop();
    const hourAvgS = solarSData.pop();
    const hourCalcAvgL =
      solarLData.reduce((acc, val) => acc + val, 0) / solarLData.length;
    const hourCalcAvgS =
      solarSData.reduce((acc, val) => acc + val, 0) / solarSData.length;

    controlLong.push({ y: hourCtrlL, x: newTimeDate });
    controlShort.push({ y: hourCtrlS, x: newTimeDate });
    averageCollectedLong.push({ y: hourAvgL, x: newTimeDate });
    averageCollectedShort.push({ y: hourAvgS, x: newTimeDate });
    averageCalcLong.push({ y: hourCalcAvgL, x: newTimeDate });
    averageCalcShort.push({ y: hourCalcAvgS, x: newTimeDate });

    percentiles.forEach((pctl) => {
      const pctlValL = calculatePercentile(solarLData, pctl);
      const pctlValS = calculatePercentile(solarSData, pctl);

      percentilesLWave[`p${pctl}`].push({ y: pctlValL, x: newTimeDate });
      percentilesSWave[`p${pctl}`].push({ y: pctlValS, x: newTimeDate });
    });
  });

  const interpolatedPercentilesLWave = Object.keys(percentilesLWave).reduce(
    (acc, curr) => {
      return { ...acc, [curr]: interpolatExpandArray(percentilesLWave[curr]) };
    },
    {}
  );

  const interpolatedPercentilesSWave = Object.keys(percentilesSWave).reduce(
    (acc, curr) => {
      return { ...acc, [curr]: interpolatExpandArray(percentilesSWave[curr]) };
    },
    {}
  );

  return {
    hoursUTC, //delete?
    raw: {
      lWave: {
        percentiles: percentilesLWave,
        average: averageCalcLong,
        control: controlLong,
      },
      sWave: {
        percentiles: percentilesSWave,
        average: averageCalcShort,
        control: controlShort,
      },
    },
    interpolated: {
      lWave: {
        percentiles: interpolatedPercentilesLWave,
        average: interpolatExpandArray(averageCalcLong),
        control: interpolatExpandArray(controlLong),
      },
      sWave: {
        percentiles: interpolatedPercentilesSWave,
        average: interpolatExpandArray(averageCalcShort),
        control: interpolatExpandArray(controlShort),
      },
    },
  };
};

// Ensemble
export const formatRawSolarInputs = (datta, solarEnergyRaw) => {
  const { weatherData } = datta;
  const percentiles = [10, 30, 50, 70, 90];
  const solarEnergyPercentiles = percentiles.reduce(
    (acc, curr) => ({ ...acc, [`p${curr}`]: [] }),
    {}
  );

  //OHHFC
  const dateTimearrayOHHFC = weatherData["ohhfc"].datetime;
  const ohhfcData = dateTimearrayOHHFC.map((dateKey, i) => ({
    y: solarEnergyRaw["ohhfc"].pac[i],
    x: new Date(`${dateKey}Z`),
  }));

  // THEFW
  const controlArray = [];
  const averageArray = [];
  const dateTimearrayTHEFW = weatherData["1"].datetime; //This is from our original datatime :?
  dateTimearrayTHEFW.forEach((dateKey, i) => {
    const collectedTime = [];
    const dateTimeStamp = new Date(`${dateKey}Z`);
    for (let j = 1; j <= 30; j++) {
      collectedTime.push(solarEnergyRaw[j].pac[i]);
    }
    const calcAverage = collectedTime.reduce((a, b) => a + b, 0) / 30;
    percentiles.forEach((pctl) => {
      const pctlVal = calculatePercentile(collectedTime, pctl);
      solarEnergyPercentiles[`p${pctl}`].push({ y: pctlVal, x: dateTimeStamp });
    });

    controlArray.push({ y: solarEnergyRaw["ctrl"].pac[i], x: dateTimeStamp });
    averageArray.push({ y: calcAverage, x: dateTimeStamp });
  });

  const interpolatedEnergyPercentiles = Object.keys(
    solarEnergyPercentiles
  ).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: interpolatExpandArray(solarEnergyPercentiles[curr]),
    };
  }, {});

  return {
    ohhfc: ohhfcData,
    raw: {
      Power: {
        percentiles: solarEnergyPercentiles,
        control: controlArray,
        average: averageArray,
      },
    },
    interpolated: {
      Power: {
        percentiles: interpolatedEnergyPercentiles,
        control: interpolatExpandArray(controlArray),
        average: interpolatExpandArray(averageArray),
      },
    },
  };
};

// Craig Move to Util?
const formatWindData = (winduNewRaw, windvNewRaw, submitTime, hourArray) => {
  const winduNew = [...winduNewRaw];
  const windvNew = [...windvNewRaw];
  const [year, month, day, hour] = submitTime.split("-");
  const recordStartDate = new Date(Date.UTC(year, month - 1, day, hour));

  const hours = hourArray;
  const hourMag = {};
  const hourDirection = {};
  hours.forEach((hourKey, i) => {
    const windu = winduNew[i];
    const windv = windvNew[i];

    const magnitudeArray = [];
    const degreeArray = [];

    let count = 0;
    for (let j = 0; j < windu.length; j++) {
      const vector1 = windu[j] ** 2;
      const vector2 = windv[j] ** 2;
      magnitudeArray.push(Math.sqrt(vector1 + vector2));
      let angleRadians = Math.atan2(windv[j], windu[j]);

      // Adjust the angle to ensure it is from where the wind is coming
      angleRadians += Math.PI;
      // Convert the angle from radians to degrees
      let angleDegrees = angleRadians * (180 / Math.PI);
      // Normalize the angle to be within the range [0, 360)
      angleDegrees = (angleDegrees + 360) % 360;

      // wind_direction = (angle_degrees + 360) % 360
      degreeArray.push(angleDegrees);
      count++;
    }
    hourMag[hourKey] = magnitudeArray;
    hourDirection[hourKey] = degreeArray;
  });

  const hourMagCopy = JSON.parse(JSON.stringify(hourMag)); //Deep copy

  const percentiles = [10, 30, 50, 70, 90];
  const percentilesCalcData = percentiles.reduce(
    (acc, curr) => ({ ...acc, [`p${curr}`]: [] }),
    {}
  );
  const collectedControl = [];
  const collectedAverage = [];
  const calculatedAverage = [];

  const percentilesDirCalcData = percentiles.reduce(
    (acc, curr) => ({ ...acc, [`p${curr}`]: [] }),
    {}
  );
  const collectedDirControl = [];
  const collectedDirAverage = [];
  const calculatedDirAverage = [];
  const hoursUTC = [];

  hours.forEach((hourKey) => {
    const hourData = hourMag[hourKey];

    const millisecondsToAdd = parseInt(hourKey) * 60 * 60 * 1000;
    var newTime = recordStartDate.getTime() + millisecondsToAdd;
    hoursUTC.push(new Date(newTime));
    const newTimeDate = new Date(newTime);

    const ensembleCount = hourData.length;

    const hourCtrl = hourData.pop();
    collectedControl.push({ y: hourCtrl, x: newTimeDate });

    // for gefs pgrb2bp5 average is not included, but we don't need it, if the ensmble count is 32 avg is included, 31 means it isn't
    if (ensembleCount === 32) {
      const hourAvg = hourData.pop();
      collectedAverage.push({ y: hourAvg, x: newTimeDate });
    }

    const hourCalcAvg =
      hourData.reduce((acc, val) => acc + val, 0) / hourData.length;
    calculatedAverage.push({ y: hourCalcAvg, x: newTimeDate });

    percentiles.forEach((pctl) => {
      const pctlVal = calculatePercentile(hourData, pctl);
      percentilesCalcData[`p${pctl}`].push({ y: pctlVal, x: newTimeDate });
    });

    const hourDirData = hourDirection[hourKey];
    const hourDirCtrl = hourDirData.pop();
    collectedDirControl.push({ y: hourDirCtrl, x: newTimeDate });

    if (ensembleCount === 32) {
      const hourDirAvg = hourDirData.pop();
      collectedDirAverage.push({ y: hourDirAvg, x: newTimeDate });
    }

    const hourDirCalcAvg =
      hourDirData.reduce((acc, val) => acc + val, 0) / hourDirData.length;
    calculatedDirAverage.push({ y: hourDirCalcAvg, x: newTimeDate });

    percentiles.forEach((pctl) => {
      const pctlVal = calculatePercentile(hourDirData, pctl);
      percentilesDirCalcData[`p${pctl}`].push({ y: pctlVal, x: newTimeDate });
    });
  });
  const magPercentile = Object.keys(percentilesCalcData).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: interpolate(percentilesCalcData[curr]),
    };
  }, {});

  const dirPercentile = Object.keys(percentilesDirCalcData).reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr]: interpolate(percentilesDirCalcData[curr]),
      };
    },
    {}
  );

  const magControl = interpolate(collectedControl);
  const magAverage = interpolate(calculatedAverage);
  const dirControl = interpolate(collectedDirControl);
  const dirAverage = interpolate(calculatedDirAverage);

  const interpolatedMagEnsemble = interpolateValues(hourMagCopy);
  const isoKeyMagEnsemble = Object.keys(hourMagCopy).reduce((acc, curr) => {
    const millisecondsToAdd = parseInt(curr) * 60 * 60 * 1000;
    var newTime = recordStartDate.getTime() + millisecondsToAdd;
    return { ...acc, [newTime]: hourMagCopy[curr] };
  }, {});

  const isoKeyMagEnsembleInterpolate = Object.keys(
    interpolatedMagEnsemble
  ).reduce((acc, curr) => {
    const millisecondsToAdd = parseInt(curr) * 60 * 60 * 1000;
    var newTime = recordStartDate.getTime() + millisecondsToAdd;
    return { ...acc, [newTime]: interpolatedMagEnsemble[curr] };
  }, {});

  return {
    hours, //delete?
    hoursUTC, //delete?
    hourMagEnsemble: hourMagCopy, //Only needed for solar at 10m wind,
    isoKeyMagEnsemble,
    isoKeyMagEnsembleInterpolate,
    raw: {
      Speed: {
        percentiles: percentilesCalcData,
        average: calculatedAverage,
        control: collectedControl,
      },
      Direction: {
        percentiles: percentilesDirCalcData,
        average: calculatedDirAverage,
        control: collectedDirControl,
      },
    },
    interpolated: {
      Speed: {
        percentiles: magPercentile,
        average: magAverage,
        control: magControl,
      },
      Direction: {
        percentiles: dirPercentile,
        average: dirAverage,
        control: dirControl,
      },
    },
  };
};

function interpolate(data) {
  // Helper function to linearly interpolate between two points
  function interpolateValue(y1, y2, x1, x2, x) {
    return y1 + (y2 - y1) * ((x - x1) / (x2 - x1));
  }

  // Convert date strings to Date objects and sort by date
  // data = data.map(d => ({ ...d, x: new Date(d.x) })).sort((a, b) => a.x - b.x);

  const result = [];
  for (let i = 0; i < data.length - 1; i++) {
    const start = data[i];
    const end = data[i + 1];

    // Add the starting point
    result.push({ y: start.y, x: start.x.toISOString() });

    // Calculate interpolated points
    let current = new Date(start.x);
    const endDate = new Date(end.x);

    // Increment the date by 1 hour until we reach the end date
    while (current < endDate) {
      current.setHours(current.getHours() + 1);
      if (current >= endDate) break;

      const interpolatedY = interpolateValue(
        start.y,
        end.y,
        start.x.getTime(),
        end.x.getTime(),
        current.getTime()
      );
      result.push({ y: interpolatedY, x: current.toISOString() });
    }
  }

  // Add the last point
  result.push({
    y: data[data.length - 1].y,
    x: data[data.length - 1].x.toISOString(),
  });

  return result;
}

// for magnitude interpolation
export const interpolateValues = (dataObject) => {
  const keys = Object.keys(dataObject)
    .map(Number)
    .sort((a, b) => a - b);
  const interpolatedData = { ...dataObject };

  for (let i = 0; i < keys.length - 1; i++) {
    const startKey = keys[i];
    const endKey = keys[i + 1];
    const startArray = dataObject[startKey];
    const endArray = dataObject[endKey];
    const gap = endKey - startKey;

    for (let j = 1; j < gap; j++) {
      const interpolatedKey = startKey + j;
      interpolatedData[interpolatedKey] = startArray.map(
        (startValue, index) => {
          const endValue = endArray[index];
          const interpolatedValue =
            startValue + ((endValue - startValue) * j) / gap;
          return interpolatedValue;
        }
      );
    }
  }

  return interpolatedData;
};

const cumulativeCalc = (srcArray) => {
  return srcArray.reduce((acc, curr, i, array) => {
    if (i === 0) {
      return [curr];
    }
    return [...acc, { ...curr, y: acc[i - 1].y + curr.y }];
  }, []);
};
// Craig move to util?
export const formatPrecipData = (thefwDataRaw, submitTime, hourArray) => {
  const thefwData = [...thefwDataRaw];
  const [year, month, day, hour] = submitTime.split("-");
  const recordStartDate = new Date(Date.UTC(year, month - 1, day, hour));
  const percentiles = [10, 30, 50, 70, 90];
  const percentilesCalcData = percentiles.reduce(
    (acc, curr) => ({ ...acc, [`p${curr}`]: [] }),
    {}
  );
  const accPercentilesCalcData = percentiles.reduce(
    (acc, curr) => ({ ...acc, [`p${curr}`]: [] }),
    {}
  );
  const hours = hourArray;
  const collectedControl = [];
  const collectedAverage = [];
  const calculatedAverage = [];
  const hoursUTC = [];

  hours.forEach((hourKey, i) => {
    const hourData = thefwData[i];
    const hourCtrl = hourData.pop();

    const hourAvg = hourData.pop();
    const hourCalcAvg =
      hourData.reduce((acc, val) => acc + val, 0) / hourData.length;

    const millisecondsToAdd = parseInt(hourKey) * 60 * 60 * 1000;
    var newTime = recordStartDate.getTime() + millisecondsToAdd;
    hoursUTC.push(new Date(newTime));
    const newTimeDate = new Date(newTime);

    collectedControl.push({ y: hourCtrl, x: newTimeDate });
    collectedAverage.push({ y: hourAvg, x: newTimeDate });
    calculatedAverage.push({ y: hourCalcAvg, x: newTimeDate });

    percentiles.forEach((pctl) => {
      const pctlVal = calculatePercentile(hourData, pctl);
      // const accPctlVal = cumulativeCalc(pctlVal)
      percentilesCalcData[`p${pctl}`].push({ y: pctlVal, x: newTimeDate });
      // accPercentilesCalcData[`p${pctl}`].push(accPctlVal)
    });
  });

  // Craig Adjust this
  Object.keys(percentilesCalcData).forEach((key) => {
    const pctlData = percentilesCalcData[key];
    accPercentilesCalcData[key] = cumulativeCalc(pctlData);
  });

  const accCollectedControl = cumulativeCalc(collectedControl);
  const accCollectedAverage = cumulativeCalc(collectedAverage);
  const accCalculatedAverage = cumulativeCalc(calculatedAverage);

  const interpolatedControl = spreadHourlyData(collectedControl);
  const interpolatedAverage = spreadHourlyData(calculatedAverage);
  const interpolatedPercentiles = Object.keys(percentilesCalcData).reduce(
    (acc, curr) => {
      return { ...acc, [curr]: spreadHourlyData(percentilesCalcData[curr]) };
    },
    {}
  );

  // Craig Spread doesn't work for even dist... pass in cumulative.
  const interpolatedAccControl = cumulativeCalc(interpolatedControl);
  const interpolatedAccAverage = cumulativeCalc(interpolatedAverage);
  const interpolatedAccPercentiles = Object.keys(
    interpolatedPercentiles
  ).reduce((acc, curr) => {
    return { ...acc, [curr]: cumulativeCalc(interpolatedPercentiles[curr]) };
  }, {});

  return {
    hours, //delete?
    hoursUTC, //delete?
    raw: {
      Hourly: {
        percentiles: percentilesCalcData,
        average: calculatedAverage,
        control: collectedControl,
      },
      Cumulative: {
        percentiles: accPercentilesCalcData,
        average: accCalculatedAverage,
        control: accCollectedControl,
      },
    },
    interpolated: {
      Hourly: {
        percentiles: interpolatedPercentiles,
        average: interpolatedAverage,
        control: interpolatedControl,
      },
      Cumulative: {
        percentiles: interpolatedAccPercentiles,
        average: interpolatedAccAverage,
        control: interpolatedAccControl,
      },
    },
  };
};

// for averging values over date-times
export const formatInterpolateParam = (thefwDataRaw, submitTime, hourArray) => {
  const thefwData = [...thefwDataRaw];
  const [year, month, day, hour] = submitTime.split("-");
  const recordStartDate = new Date(Date.UTC(year, month - 1, day, hour));
  const percentiles = [10, 30, 50, 70, 90];
  const percentilesCalcData = percentiles.reduce(
    (acc, curr) => ({ ...acc, [`p${curr}`]: [] }),
    {}
  );
 
  const hours = hourArray;
  const collectedControl = [];
  const collectedAverage = [];
  const calculatedAverage = [];
  const hoursUTC = [];

  hours.forEach((hourKey, i) => {
    const hourData = thefwData[i];
    const hourCtrl = hourData.pop();

    const hourAvg = hourData.pop();
    const hourCalcAvg =
      hourData.reduce((acc, val) => acc + val, 0) / hourData.length;

    const millisecondsToAdd = parseInt(hourKey) * 60 * 60 * 1000;
    var newTime = recordStartDate.getTime() + millisecondsToAdd;
    hoursUTC.push(new Date(newTime));
    const newTimeDate = new Date(newTime);

    collectedControl.push({ y: hourCtrl, x: newTimeDate });
    collectedAverage.push({ y: hourAvg, x: newTimeDate });
    calculatedAverage.push({ y: hourCalcAvg, x: newTimeDate });

    percentiles.forEach((pctl) => {
      const pctlVal = calculatePercentile(hourData, pctl);
      // const accPctlVal = cumulativeCalc(pctlVal)
      percentilesCalcData[`p${pctl}`].push({ y: pctlVal, x: newTimeDate });
    });
  });

  const interpolatedControl = interpolate(collectedControl);
  const interpolatedAverage = interpolate(calculatedAverage);
  const interpolatedPercentiles = Object.keys(percentilesCalcData).reduce(
    (acc, curr) => {
      return { ...acc, [curr]: interpolate(percentilesCalcData[curr]) };
    },
    {}
  );

  return {
    hours, //delete?
    hoursUTC, //delete?
    raw: {
      Hourly: {
        percentiles: percentilesCalcData,
        average: calculatedAverage,
        control: collectedControl,
      },
    },
    interpolated: {
      Hourly: {
        percentiles: interpolatedPercentiles,
        average: interpolatedAverage,
        control: interpolatedControl,
      }
    },
  };
};

function spreadHourlyData(data) {
  const hourlyData = [];

  data.forEach((item, index) => {
    const valuePerHour = item.y / 3;
    const startTime = new Date(item.x);

    for (let i = 2; i >= 0; i--) {
      const hourTime = new Date(startTime.getTime() - i * 60 * 60 * 1000);
      hourlyData.push({
        y: valuePerHour,
        x: hourTime.toISOString(),
      });
    }
  });

  return hourlyData;
}

function copyHourlyData(data) {
  const hourlyData = [];

  data.forEach((item) => {
    const originalValue = item.y;
    const startTime = new Date(item.x);

    for (let i = 2; i >= 0; i--) {
      const hourTime = new Date(startTime.getTime() - i * 60 * 60 * 1000);
      hourlyData.push({
        y: originalValue,
        x: hourTime.toISOString(),
      });
    }
  });

  return hourlyData;
}

// originalpCurveArray must be sorted lowest to highest
export const interpolateCurveValues = (
  originalpCurveArray,
  windMagnitudeArray
) => {
  const pCurveArray = [...originalpCurveArray];
  return windMagnitudeArray.map((record) => {
    let lowerBound = null;
    let upperBound = null;
    for (const point of pCurveArray) {
      if (point.x <= record.y) {
        lowerBound = point;
      } else {
        upperBound = point;
        break;
      }
    }

    // If x is outside the range, use the closest point's y value
    if (!lowerBound) {
      return { x: record.x, y: upperBound.y }; // x is lower than any point's x value
    }
    if (!upperBound) {
      return { x: record.x, y: lowerBound.y }; // x is higher than any point's x value
    }
    // Perform linear interpolation
    const slope = (upperBound.y - lowerBound.y) / (upperBound.x - lowerBound.x);
    const interpolatedValue = slope * (record.y - lowerBound.x) + lowerBound.y;
    return { x: record.x, y: interpolatedValue };
  });
};

export const interpolateCurveValuesNoDates = (
  originalpCurveArray,
  windMagnitudeArray
) => {
  const pCurveArray = [...originalpCurveArray];
  return windMagnitudeArray.map((record) => {
    let lowerBound = null;
    let upperBound = null;
    for (const point of pCurveArray) {
      if (point.x <= record) {
        lowerBound = point;
      } else {
        upperBound = point;
        break;
      }
    }

    // If x is outside the range, use the closest point's y value
    if (!lowerBound) {
      return upperBound.y; // x is lower than any point's x value
    }
    if (!upperBound) {
      return lowerBound.y; // x is higher than any point's x value
    }
    // Perform linear interpolation
    const slope = (upperBound.y - lowerBound.y) / (upperBound.x - lowerBound.x);
    const interpolatedValue = slope * (record - lowerBound.x) + lowerBound.y;
    return interpolatedValue;
  });
};
