// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateClient } from "aws-amplify/api";
import { listUsersCustom, getUserCustom, listOrganizationAndMembersCustom } from "../graphql/customQueries";
import { updateUserCustom } from "../graphql/customMutations";
import { get } from "aws-amplify/api";
import { listUsers } from "../graphql/queries";

export const authenticationState = {
  unfetched: "unfetched", //initial state
  unAuthenticated: "unAuthenticated",
  authenticated: "authenticated",
};

const initialState = {
  status: authenticationState.unfetched,
  details: {},
  permissionDetails: {},
  organizations:{ loaded: false,
    data:{}
  }
};

const client = generateClient();

// Remove this enventually
export const getMeUser = createAsyncThunk(
  "user/getDetails",
  async (id, thunkAPI) => {
    try {
      // Craig, later get based off of ID, fetch user, get Id, then fetch
      const { data } = await client.graphql({
        query: getUserCustom,
        variables: { id },
      });

      return data.getUser;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Just for profile Pic
export const updateUserRequest =  createAsyncThunk(
  "user/updateUser",
  async (input, thunkAPI) => {
    try {
      // Craig, later get based off of ID, fetch user, get Id, then fetch
      const { data } = await client.graphql({
        query: updateUserCustom,
        variables: { input },
      });
      return data.updateUser;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getMyOrganizations = createAsyncThunk(
  "user/getMyOrganizations",
  async (input, thunkAPI) => {

    try {
      // Craig, later get based off of ID, fetch user, get Id, then fetch
      // const { data } = await client.graphql({
      //   query: listUsers,
      // });
      // console.log("DATA", data)
      const { data } = await client.graphql({
        query: listOrganizationAndMembersCustom,
      });
      const { listOrganizations, listUserOrganizations  } = data
      const organizationDict = listOrganizations.items.reduce((acc, curr) => {
        return {...acc, [curr.id]: {...curr, members:[]}}
      },{})
      listUserOrganizations.items.forEach(userOrg => {
        try{
          organizationDict[userOrg.organizationId].members.push(userOrg.user)
        } catch (e) {
          console.log("couldn't complete user")
        }
      });

      return organizationDict;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
)

const UserSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.status = authenticationState.authenticated;
      state.details = action.payload;
    },
    logOutUser: (state, action) => {
      state.status = authenticationState.unAuthenticated;
      state.details = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyOrganizations.fulfilled, (state, action) => {
        //newHire
        // state.permissionDetails = action.payload;
        state.organizations.data = action.payload;
        state.organizations.loaded = true
      })
      .addCase(getMyOrganizations.pending, (state, action) => {
        //newHire
        // state.permissionDetails = action.payload;
      }).addCase(getMyOrganizations.rejected, (state, action) => {
        //newHire
        // state.permissionDetails = action.payload;
      })
      .addCase(getMeUser.pending, (state, action) => {
        //newHire
      })
      .addCase(getMeUser.fulfilled, (state, action) => {
        //newHire
        state.permissionDetails = action.payload;
      })
      .addCase(getMeUser.rejected, (state, action) => {
        //newHire
      })
      .addCase(updateUserRequest.fulfilled, (state, action) => {
        //newHire
        // state.permissionDetails = action.payload;
        state.permissionDetails = action.payload;
      })
      .addCase(updateUserRequest.pending, (state, action) => {
        //newHire
        // state.permissionDetails = action.payload;
      }).addCase(updateUserRequest.rejected, (state, action) => {
        //newHire
        // state.permissionDetails = action.payload;
      })
  },
});

export const { setUserDetails, logOutUser } = UserSlice.actions;
export default UserSlice.reducer;
