// @ts-nocheck
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import AssetSelectInput from './AssetSelectInput'
import ProcessedModelDataSourceForm from './ProcessedModelDataSourceForm';
import SensitivityModelDataSourceForm from './SensitivityModelDataSourceForm';
import { blankDataSource } from './dataSourceSchemas';
import { disable } from 'aws-amplify/analytics';

const assetForecastTypesEnum = {
    "240hr3": {label: "240hr forecast 3hr resolution - under Construction", disabled:true},
    "48hr1": {label: "48hr forecast 1hr resolution - under Construction", disabled:true},
  };


const DataTypeFormAssetForecast = ({ onDataSourceChange, dataSourceRecord, onSubmitForm }) => {
    const { assets, assetGroups } = useSelector(({ assets, assetGroups }) => {
        //Craig use this later for decomissioning redux assets
        const FlattenedAssets = Object.values(assets.dataGroups) // Get the arrays for each asset group
        .flatMap(assetGroup => assetGroup) // Flatten the nested arrays of GeoPoints
        
        return {
          assets: FlattenedAssets,
          assetGroups:assetGroups.groups
        }
      });
      const [selectedAsset, setSelectedAsset] = useState("")
      const [selectedForecastType, setSelectedForecastType] = useState("")
      const [forecastTypeList, setforecastTypeList] = useState([])

    const handleAssetSelect = (asset) => {
        
        let forecastList = { ...assetForecastTypesEnum}
        if(asset?.processedModels?.items?.some((item) => item.modelType ===  "SENSITIVITY")){
          forecastList = {
            ["sensitivityModels"]: {label: "Sensitivity Models", disabled:false},
            ...forecastList
          }
        }
        
        if(asset?.processedModels?.items?.some((item) => item.modelType === "PROCESSED")) {
          forecastList = {
            ["processedModels"]: {label: "Processed Models", disabled:false},
            ...forecastList
          }
        }
        const forecastListKeys = Object.keys(forecastList)
        if(!forecastListKeys.includes(selectedForecastType)) {
          setSelectedForecastType(forecastListKeys[0])
        }
        
        setforecastTypeList(forecastList)
        setSelectedAsset(asset)
    }

    const renderNextStep = () => {
    switch (selectedForecastType) {
      case "processedModels":
        return (
          <ProcessedModelDataSourceForm
            processedModels={selectedAsset?.processedModels?.items?.filter(
              (item) => item.modelType === "PROCESSED"
            )}
            dataSourceRecord={dataSourceRecord}
            onSourceChange={onDataSourceChange}
            onSubmit={onSubmitForm}
            selectedAssetId={selectedAsset.id}
          />
        );
      case "sensitivityModels":
        return (
          <SensitivityModelDataSourceForm
            sensitivityModels={selectedAsset?.processedModels?.items?.filter(
              (item) => item.modelType === "SENSITIVITY"
            )}
            dataSourceRecord={dataSourceRecord}
            onSourceChange={onDataSourceChange}
            onSubmit={onSubmitForm}
            selectedAssetId={selectedAsset.id}
          />);
      default:
        return <div>Not available</div>;
    }
  };

  return (
    <div>
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Asset
        </div>
        <AssetSelectInput 
        selectedAsset={selectedAsset} 
        assetList={assets} 
        assetGroups={assetGroups}
        onChange={handleAssetSelect} />
        { selectedAsset &&
        <>
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Forecast Type
        </div>
        <select
          value={selectedForecastType}
          onChange={(e)=>setSelectedForecastType(e.target.value)}
          className="flex grow border p-3 mb-5 text-md rounded-md"
        >
          <option value="" disabled selected>
            Select one
          </option>
          {Object.entries(forecastTypeList).map((entry) => {
            const [key, desc] = entry
            return <option key={key} value={key} disabled={desc.disabled}>{desc.label}</option>
          })}
        </select>
        </>
        }   
        {renderNextStep()}
    </div>
  )
}

export default DataTypeFormAssetForecast