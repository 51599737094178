// @ts-nocheck
import React from 'react'
import { render } from 'react-dom'

const ProcessedModelStyleUpdate = ({dataSourceRecord, onChange}) => {
    const [id] = Object.keys(dataSourceRecord)
    const record = dataSourceRecord[id]
    const {resource, renderProps} = record
    const { currentVariation, selectedChannel, variations
    } = renderProps

    const toggleVisibility = (e, channel, variationKey) => {
        const updatedRenderProps = structuredClone(renderProps)
        updatedRenderProps.variations[variationKey][channel].visible = !updatedRenderProps.variations[variationKey][channel].visible

        onChange({
            [id]: {
                ...record,
                renderProps:updatedRenderProps
            }
        })
    }

    const renderChecks = (variationKey, renderData) => {
        return Object.entries(renderData).map((item) => {
            const [channel, channelRender] = item
            return(
                <div className='flex'>
                    <div>{channel}</div>
                    <div>
                    <input
                        type="checkbox"
                        checked={channelRender.visible}
                        onChange={(e) => toggleVisibility(e, channel, variationKey)}
                        className="ml-2"
                    />
                </div>
                </div>
            )
        })
    }

  return (
    <div>
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Parameters
        </div>
        {Object.entries(variations).map(variation => {
            const [variationKey, renderData] = variation
            return (<div>
                        <div>{variationKey}</div>
                        {renderChecks(variationKey, renderData)}
                    </div>
                )

        })}
    </div>
  )
}

export default ProcessedModelStyleUpdate