// @ts-nocheck
import React, { useEffect, useState } from 'react'
import withAdminAuthComponent from '../HOC/withAdminAuthComponent'
// import { listUsers } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { createOrganization, createUserOrganizations } from '../../graphql/mutations';
import { createUserOrganizationsCustom } from '../../graphql/customMutations'
import ProfilePictureRounded from '../ProfilePictureRounded';
import { uploadData } from '@aws-amplify/storage';
import { listUsers } from '../../graphql/queries';

const defaultFormState = {
  name:"",
  description:"",
  owner:""
}

const client = generateClient();

const OrganizationUpdateMemberShip = ({organizationId}) => {
    const [file, setFile] = useState(null);
        // const [selectedImage, setSelectedImage] = useState(null);
        const [userList, setUserList] = useState([])
        // const [formState, setFormState] = useState(defaultFormState)
        const [selectedItems, setSelectedItems] = useState([]);
        const [formComplete, setFormComplete] = useState(false)
        // const [organizationOwner, setOrganizationOwner] = useState(null)
    
        // set user state
        const getAllUsers = async () => {
            const client = generateClient();
            const { data } = await client.graphql({
                query: listUsers,
              });
              setUserList(data.listUsers.items)
        }
        // submit if it works, green light!
    
        useEffect(()=>{
            getAllUsers()
        },[])
    
        
    
        const handleSubmit = async (e) => {
            e.preventDefault()
            try{
    
                for (const userId of selectedItems) {
                    console.log(`userId: ${userId}, organizationId: ${organizationId}`)
                    const { data } = await client.graphql({
                        query: createUserOrganizationsCustom,
                        variables: { input:{
                          organizationID: organizationId,
                            userID: userId
                                    } 
                                },
                        });
                    console.log("Create user Organization", data)
                }
                // setFormState({})
                setSelectedItems([])
                setFormComplete(true)
    
            } catch (e) {
                console.log("errror", e)
            }
        }
    
        const isSelected = (id) => selectedItems.includes(id);
    
        const handleSelect = (id) => {
            setSelectedItems((prevSelected) => {
              if (prevSelected.includes(id)) {
                // If item is already selected, remove it
                return prevSelected.filter((item) => item !== id);
              } else {
                // If item is not selected, add it
                return [...prevSelected, id];
              }
            });
          };
  

          return (
            <div>
        
            <div className='text-lg'>Admin Update Membership: {organizationId}</div>
                <form onSubmit={handleSubmit}>
                    <div
                        name="name"
                        className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
                    >
                            Members
                    </div>
                    <ul>
                    {userList.map((option)=> (
                      <li key={option.id} style={{ marginBottom: "8px" }}>
                        <label>
                          <input
                            type="checkbox"
                            checked={isSelected(option.id)}
                            onChange={() => handleSelect(option.id)}
                          />
                          {option.name} - {option.email}
                        </label>
                      </li>
                    ))}
                  </ul>
                  <button type='submit' className='bg-blue-300 p-2 rounded m-2'>Update Membership</button>
                  {formComplete && <div className='bg-green-300'> COMPLETE!</div>}
                </form>
            </div>
          )
        
}

export default withAdminAuthComponent(OrganizationUpdateMemberShip)