// @ts-nocheck
import React, { useState, useEffect } from "react";
import { AssetBreadCrumb, NestedBreadCrumbs } from "../AssetBreadCrumb";
import AssetDescription from "../AssetDescription";
import { ReactComponent as LightningIcon } from "../../assets/icons/lightning.svg";
import ExploreDataSourceAdd from "./ExploreDataSourceAdd";
import ExploreDataSourceForm from "./ExploreDataSourceForm";
import ExploreDataSourceList from "./ExploreDataSourceList";
import usePrevious from "../../hooks/usePrevious";
import { v4 as uuidv4 } from "uuid";
import {
  dataSourceAdapters,
  fetchRecordDataSource,
  hasDataChanged,
  setSensitivityModelRenderProperties,
} from "./utils";
import ExploreGraph from "./ExploreGraph";
import { blankDataSource } from "./dataSourceSchemas";
// import dataSourceExample from './2024-11-04-08-dataSourceExample.json'

const displays = Object.freeze({
  graph: "graph",
  addDataSource: "addDataSource",
  graphWForm: "graphWForm",
  graphWDashBoard: "graphWDashBoard"
});

const networkStatus = Object.freeze({
  unfetched: "unfetched",
  loading: "loading",
  loaded: "loaded",
});

 const breadcrumbItems = [
  { route: "/home", label: "Analysis Page" },
];

const ExploreDashboard = ({ selectedAsset, assetOwner, assetGroup }) => {
  const [displayProperties, setDisplayProperties] = useState({
    view: displays.graph,
    activeDataSource:null
  });
  const [dataSourceList, setDataSourceList] = useState({});
  const previousDataSourceList = usePrevious(dataSourceList);
  

  // Need this for later
  useEffect(() => {
    const fetchChangedDataSources = async () => {
      const changedKeys = hasDataChanged(
        previousDataSourceList,
        dataSourceList
      );

      if (changedKeys.length > 0) {
        const promises = changedKeys.map(async (dataSourceId) => {
          const record = dataSourceList[dataSourceId];
          try {
            const updatedRecord = await fetchRecordDataSource(
              dataSourceId,
              record
            );

            setDataSourceList((currentList) => ({
              ...currentList,
              [dataSourceId]: updatedRecord,
            }));
          } catch (e) {
            console.log("Error, ", e);

            setDataSourceList((currentList) => ({
              ...currentList,
              [dataSourceId]: { ...record, status: "error" },
            }));
          }
        });
        await Promise.allSettled(promises);
      }
    };
    fetchChangedDataSources();
  }, [dataSourceList, previousDataSourceList]);

  const handleSubmit = () => {
    setDisplayProperties({
      view: displays.graphWDashBoard,
      activeDataSource: null,
    });
  };

  const handleDataSourceChange = async (updatedDataSourcRecord) => {
    const [updatedDataSourcRecordId] = Object.keys(updatedDataSourcRecord)
    const record = updatedDataSourcRecord[updatedDataSourcRecordId]
    setDataSourceList((currentList) => ({
      ...currentList,
      [updatedDataSourcRecordId]: record,
    }));
  };

  const renderContent = () => { 
    const formattedData = dataSourceAdapters(dataSourceList); //Craig Change location
    switch (displayProperties.view) {
      case displays.graph:
        return <ExploreGraph data={formattedData} />;
      case "addDataSource":
        return (
          <ExploreDataSourceForm
            selectedAsset={selectedAsset}
            onDataSourceChange={handleDataSourceChange}
            dataSourceRecord={{
              [displayProperties.activeDataSource]:
                dataSourceList[displayProperties.activeDataSource],
            }}
            onSubmitForm={handleSubmit}
          />
        );
      case displays.graphWForm:
        return (
          <div className="flex-1 flex-col">
            <div className="flex-1 h-1/2">
              <ExploreGraph data={formattedData} />
            </div>
            <div className="flex-1 bg-slate-200">
              <ExploreDataSourceForm
                selectedAsset={selectedAsset}
                onDataSourceChange={handleDataSourceChange}
                dataSourceRecord={{
                  [displayProperties.activeDataSource]:
                    dataSourceList[displayProperties.activeDataSource],
                }}
                onSubmitForm={handleSubmit}
              />
            </div>
          </div>
        );
        case displays.graphWDashBoard:
          return (
            <div className="flex-1 flex-col">
              <div className="flex-1 h-1/2">
                <ExploreGraph data={formattedData} />
              </div>
              <div className="flex-1 bg-slate-200">
                <div>Dashboard View</div>
              </div>
            </div>
          );

      default:

        return <p>No content available for this type.</p>;
    }
  };

  const handleDeleteDataSource = (dataSourceID) => {
    setDataSourceList((prev) => {
      const updatedDSList = { ...prev }; // Create a shallow copy
      delete updatedDSList[dataSourceID]; // Delete the key from the copy
      return updatedDSList; // Return the updated object as the new state
  });

  }

  const handleDataSourceRecordClick = (dataSourceID) => {
    setDisplayProperties({
      view: displays.graphWForm,
      activeDataSource: dataSourceID,
    });

  }

  const handleAddNewDataSource = () => {
    const id = uuidv4();
    setDataSourceList((currentList) => ({
      ...currentList,
      [id]: blankDataSource,
    }));

    setDisplayProperties({
      view: displays.graphWForm,
      activeDataSource: id,
    });
  };

  return (
    <>
      <div className="min-w-[300px] bg-stone-50 hidden xl:flex flex-col">
        <ExploreDataSourceAdd onNewDataSourceClick={handleAddNewDataSource} />
        <div>Current View: {displayProperties.view}</div>
        <button
          onClick={() => setDisplayProperties({ view: displays.graphWForm })}
        >
          graphwform
        </button>
        <button onClick={() => setDisplayProperties({ view: displays.graph })}>
          CLICK
        </button>
        <div className="flex-1 ">
          <ExploreDataSourceList dataSourceList={dataSourceList} onDelete={handleDeleteDataSource} onRecordClick={handleDataSourceRecordClick} />
        </div>
      </div>
      <div className="flex-1 ">
        <div className="flex-col flex h-screen">
          <NestedBreadCrumbs items={breadcrumbItems}>
            <LightningIcon
              stroke="#6B7280"
              className="w-5 h-5 ml-2 mr-1 cursor-pointer"
            />
          </NestedBreadCrumbs>
          <div className="flex flex-row flex-1 bg-stone-50">
            {renderContent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExploreDashboard;
