// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { listOrganizations } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { listOrganizationsCustom } from '../graphql/customQueries';
import OrganizationPreview from "../components/Organization/OrganizationPreview"
import ProfilePictureRounded from '../components/ProfilePictureRounded';
import { useSelector } from 'react-redux';

const OrganizationPreviewPage = ({ organization, organizationId}) => {
    const [organizations, setOrganizations] = useState([])
    const user = useSelector((state) => state.user);

  return (
    <div>
        {user?.organizations?.loaded && Object.keys(user?.organizations.data).map((organizationKey) => {
          const organizationData = user?.organizations.data[organizationKey]
          return (
          <div className='flex' key={organizationKey}>
            <OrganizationPreview key={organizationKey} organization={organizationData} organizationId={organizationKey} />
            <div className='flex'>
              {organizationData.profilePictureUrl &&
              <>
                <div>Company Pic Preview:</div>
                <ProfilePictureRounded className={"border-white"} pictureURL={organizationData.profilePictureUrl} diameter={32} />
              </>
              }
              </div>
          </div>)
        })}
    </div>
  )
}

export default OrganizationPreviewPage