// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";

export default (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
