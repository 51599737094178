// @ts-nocheck
import React from "react";
import { ReactComponent as HomeSmile } from "../../assets/icons/home-smile.svg";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFlyLocation } from "../store/mapStateSlice";

const DataSourceRecord = ({ dataSource, onDelete, onClick }) => {
  const dispatch = useDispatch();
  const { groupid, assetid } = useParams();
  const navigate = useNavigate();
  const handleAssetClick = () => {
    onClick()
  };

  const handleDelete = (e) => {
    onDelete()
    e.stopPropagation();
  }

  return (
    <div
      onClick={handleAssetClick}
      className={`group cursor-pointer h-[140px] relative ${
        false
          ? "bg-white border border-slate-500"
          : "bg-stone-50 border-slate-200"
      }  hover:bg-gray-200 pl-[24px] pr-[22px] py-[20px] border-b-2 `}
    >
      <div className="flex justify-between">
        <div className="flex flex-1 ">
          <div className="justify-center items-center inline-flex mr-2">
            <div className="p-2 bg-stone-50 rounded-md justify-start items-center border border-gray-200  inline-flex">
              <div className="w-6 h-6 flex justify-center ">
                <HomeSmile />
              </div>
            </div>
          </div>

          <div className="pt-1 ">
            <div className="text-gray-900 text-sm font-semibold font-['Inter']">
              {" "}
              {dataSource.attributes.name}
            </div>
            <div className="text-gray-500 text-xs font-normal font-['Inter']">
              {" "}
              {dataSource.resource.resourceType}
            </div>
          </div>
        </div>
        <div className="pt-1">
          <div onClick={handleDelete} className="px-2 py-1 bg-red-500 rounded-[100px] justify-start items-center  flex">
            <div className="text-white text-xs font-medium font-['Inter'] leading-none">
              Delete
            </div>
          </div>
        </div>
      </div>
      {/* Craig will need to change this for each piece of data */}
      <div className="flex gap-2  py-[5px] "></div>
      <div className="flex gap-[8%] border-t-2 border-slate-200 group-hover:border-gray-300">
        <div>
          <div className="text-gray-500 text-[10px] font-semibold pt-2 pb-1 px-1">
            Asset Value
          </div>
          <div className="text-gray-900 text-xs font-medium p-1">
            PlaceHolder
          </div>
        </div>
        <div>
          <div className="text-gray-500 text-[10px] font-semibold pt-2 pb-1 px-1">
            Latitude
          </div>
          <div className="text-gray-900 text-xs font-medium p-1">
             Placeholder
          </div>
        </div>
        <div>
          <div className="text-gray-500 text-[10px] font-semibold pt-2 pb-1 px-1">
            Longitude
          </div>
          <div className="text-gray-900 text-xs font-medium p-1">
             Placeholder
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSourceRecord;
