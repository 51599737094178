// @ts-nocheck
import React from "react";
import { ReactComponent as GroupIcon } from "../../assets/icons/group-icon.svg";

const ExploreDataSourceAdd = ({ onNewDataSourceClick }) => {
  return (
    <>
      <div className=" h-[93px] inline-flex pl-6 py-[20px] ">
        <div>
          <div className="text-gray-900 text-xl font-semibold leading-[30px]">
            Data Sources
          </div>
          <div className=" text-gray-400 text-sm font-normal ">
            Explore Data Sources
          </div>
        </div>
      </div>
      <div className=" h-[65px] py-[12px] pl-6 pr-[22px] bg-gray-200">
        <div className="flex flex-row ">
          <div className="justify-center items-center inline-flex">
            <div className="p-2 bg-zinc-700 rounded-md justify-start items-center  inline-flex">
              <div className="w-6 h-6 flex justify-center ">
                <GroupIcon />
              </div>
            </div>
          </div>
          <div className="ml-2 flex-1 items-center">
            <div className="text-gray-900 text-sm font-semibold font-Inter flex justify-between ">
              <div>Add Data Source</div>
              <div>
                <div className="w-6 h-[19px] px-2 bg-slate-400 rounded-[100px] flex justify-center items-center gap-1.5 inline-flex">
                  <div className="text-gray-50 text-xs font-medium font-['Inter'] leading-none">
                    <button onClick={onNewDataSourceClick}>Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-gray-500 text-xs font-normal font-Inter">
              Asset Group
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExploreDataSourceAdd;
