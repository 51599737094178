// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setModalOpen } from "../../store/modalSlice";
import { ReactComponent as GroupIcon } from "../../assets/icons/group-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { uploadData } from "@aws-amplify/storage";
import { updateUserRequest } from "../../store/userSlice";
import ProfilePictureRounded from "../ProfilePictureRounded";

const ChangeProfilePicture = () => {
    const [file, setFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const handleFileChange = (event) => {
        const fileUploaded = event.target.files[0];
            if (fileUploaded) {
            // Use FileReader to generate a preview URL
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result); // Set the preview URL
            };
            reader.readAsDataURL(fileUploaded); // Read the file as a Data URL
            setFile(fileUploaded)
            } else {
            setSelectedImage(null); // Clear preview if no file is selected
            }
      };

    const handleCancel = () => {
        dispatch(setModalOpen(false));
      }

    const handleSubmit = async () => { 
        try{

            let fileKey = await uploadFile(file)
            const res = await dispatch(updateUserRequest({
            id:user?.permissionDetails?.id,
            profilePictureUrl: fileKey
            }))
            handleCancel()
        } catch (e) {
            console.log("ERRRR", e)
        }
    }

    const uploadFile = async (file) => {
        if (!file) return null;
        try {
            const result = await uploadData({
              path: ({identityId}) => {
                let ownerCogId = identityId
                const path = `protected/${ownerCogId}/profilePic/${file.name}`  
                console.log("Path", path)
                return path
              },
              data: file,
              options: {
                // onProgress // Optional progress callback.
              }
            }).result;
          return result.path; // Return the file's S3 location key
        } catch (error) {
          console.error("Error uploading file:", error);
          return null;
        }
    }

    return (
        <div className="">
          <div className="flex flow-row grow items-center border-b border-gray-200 p-2">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <GroupIcon className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          Change Profile Picture
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
        <div className="mt-4  flex justify-around">
            <div className="text-center">
      {selectedImage ? (
          
          <img
            src={selectedImage}
            alt="Preview"
            className="w-32 h-32 rounded-full border-2 border-black object-cover"
          />)
          :
          <ProfilePictureRounded  />
  
        }
          </div>
        </div>
        
          <div className="flex items-center justify-center  pt-5">
          
              <div>

              <input
                id="profile-photo"
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 hover:cursor file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary-50 file:text-primary-700 hover:file:bg-primary-100"
                />
                </div>
              

          </div>
          <div className="flex flex-row grow space-x-3 p-5">
            <button className="flex-grow eai-btn my-1" type="submit">
              <div className="mx-3" onClick={handleCancel}>
                Cancel
              </div>
            </button>
            <button
              onClick={handleSubmit}
              className="flex-grow eai-btn-grn my-1"
              type="submit"
              form="asset-group-name-form"
            >
              <div className="mx-3">Update Profile Picture</div>
            </button>
          </div>
        </div>
      );
}

export default ChangeProfilePicture