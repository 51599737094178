// @ts-nocheck
import { getUrl } from '@aws-amplify/storage';
import React, { useEffect, useState } from 'react'
import blankProfile from "../assets/images/blankprofile.jpg"


// pictureURL is the S3 location
const ProfilePictureRounded = ({ pictureURL, diameter = 128, className, onClick }) => {
    const [profilePicUrl, setProfilePicUrl] = useState(null)

    useEffect(()=> {
        const fetchProfilePic = async () => {
          if (pictureURL) {
            try {
              const { url } = await getUrl({
                path: pictureURL, // Path to the file in your S3 bucket
                options: {
                  validateObjectExistence: true, // Optional: Validate the file exists
                  expiresIn: 900, // Signed URL validity in seconds (default: 900, max: 3600)
                  // useAccelerateEndpoint: true, // Optional: Use S3 Transfer Acceleration
                },
              });
              setProfilePicUrl(url);
            } catch (error) {
              console.error('Error fetching profile picture URL:', error);
            }
          }
        };
    
        fetchProfilePic();
    
      },[pictureURL])

  return (
    <div
    onClick={onClick}
    style={{
        width: `${diameter}px`,
        height: `${diameter}px`,
      }}
        className={` rounded-full border-2 overflow-hidden ${className}`}>
         {pictureURL ? 
         <img
        src={profilePicUrl}
        // alt="Profile picture"
        className="w-full h-full object-cover bg-eai-primary"
        />
        :
        <img
        src={blankProfile}
        alt="Profile picture"
        className="w-full h-full object-cover"
        />
        
         }
    </div>             
  )
}

export default ProfilePictureRounded