// @ts-nocheck
import React, { useState, useRef, useLayoutEffect } from "react";
import AssetListItem from "./AssetListItem";
import AssetListItemNEW from "./AssetListItemNEW";
import DataSourceRecord from "./DataSourceRecord";

const ExploreDataSourceList = ({ dataSourceList = {}, onDelete, onRecordClick }) => {
  const [bottomDistance, setBottomDistance] = useState(0);
  const divRef = useRef(null);
  
  useLayoutEffect(() => {
    const calculateBottomDistance = () => {
      const viewportHeight = window.innerHeight;
      const { top } = divRef.current.getBoundingClientRect();
      setBottomDistance(viewportHeight - top);
    };

    calculateBottomDistance();

    // Optional: Recalculate when the window is resized
    window.addEventListener("resize", calculateBottomDistance);

    // Cleanup event listener
    return () => window.removeEventListener("resize", calculateBottomDistance);
  }, []);

  const populateList = () => {
  
    return Object.entries(dataSourceList).map((dataSourceCouple) => {
      const [dataSourceId, dataSource] = dataSourceCouple;

      return <DataSourceRecord 
              dataSource={dataSource} 
              onClick={()=>onRecordClick(dataSourceId)}
              onDelete={()=>onDelete(dataSourceId)} />
            });
  };

  return (
    <div
      style={{ height: `${bottomDistance}px` }}
      className={`scrollable-div  overflow-y-auto`}
      ref={divRef}
    >
      {populateList()}
    </div>
  );
};

export default ExploreDataSourceList;
